import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import RenderRoutes from './RenderRoutes';
import { BrowserRouter } from "react-router-dom";
import zhTW from 'antd/locale/zh_TW';
import { ConfigProvider } from 'antd';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store } from './stores/index'
import { Provider } from 'react-redux'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="1068689415672-87hdbsp9r918rbdobqnv0h0e2r0e8tqd.apps.googleusercontent.com">
      <ConfigProvider locale={zhTW}
        theme={{
          token: {
            colorPrimary: '#E25E4C',

          },
          components: {
            Layout: {
              headerBg: '#fff'
            },
          },
        }}
      >
        <React.StrictMode>
          <BrowserRouter>
            <RenderRoutes />
          </BrowserRouter>
        </React.StrictMode>
      </ConfigProvider>
    </GoogleOAuthProvider>
  </Provider >,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
