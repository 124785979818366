import {
  Modal, Breadcrumb, Spin, Select, Layout, Card, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker,
} from 'antd';
import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { PlusOutlined, UnorderedListOutlined, AppstoreOutlined, RobotOutlined } from '@ant-design/icons';
import { FaSchoolFlag, FaMapLocationDot, FaFacebook, FaLinkedin, FaInstagram, FaGoogle } from "react-icons/fa6";
import { SiCashapp } from "react-icons/si";
import { GrGroup } from "react-icons/gr";
import { GiLovers } from "react-icons/gi";
import { BsCashCoin } from "react-icons/bs";
import { FaRegThumbsUp } from "react-icons/fa";
import { Link } from 'react-router-dom';
import '../../assets/styles/LandingPage.css';
import request from '../../requests/api';
import ImageFile from '../Image/ImageFile';
import { getImageBinaryData } from '../Image/ImageFileBlob';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, EllipsisOutlined, SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import ImageGallery from "react-image-gallery";
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';
const { Text } = Typography;
const LandingPage = () => {
  const { Meta } = Card;
  const [current, setCurrent] = useState(0);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [price, setPrice] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageFile, setImageFile] = useState(null)
  const [productName, setProductName] = useState(null)
  const navigate = useNavigate();
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(0);
  };
  const contentStyle = {
    margin: 0,
    height: '310px',
    width: '100%',
    textAlign: 'center',
  };
  const fetchImages = (imagePaths) => {
    return imagePaths.map((path) => {
      return {
        original: `${process.env.REACT_APP_API_URL}/bnbarter/mem-inventory-image/${path}`,
        thumbnail: `${process.env.REACT_APP_API_URL}/bnbarter/mem-inventory-image/${path}`,
      };
    });


  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const fetchMemPost = async () => {
    try {
      const response = await request.get('/bnbarter/mem-post-guest');
      console.log(response.data)
      setDatas(response.data.datas);
    } catch (err) {
      navigate('/login')
    } finally {
      //navigate('/login')
    }
  };
  useEffect(() => {
    fetchMemPost();
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSettingClick = async (id, postName) => {
    setLoading(true);
    setIsModalVisible(true)
    setResult('')
    const formData = new FormData();
    setProductName('')
    const binaryData = await getImageBinaryData(id);
    if (binaryData) {
      const blob = new Blob([binaryData], { type: 'image/jpeg' });
      formData.append('file', blob, 'image.jpg');
      formData.append('productName', postName);
    } else {
      console.error('Failed to retrieve image data');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://rocker-ai.com:5000/upload_a', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('API response:', result);
      const resultPrice = result.split('\n')
      setResult(result.replace(resultPrice[0], ''));
      setPrice(resultPrice[0])
      setIsModalVisible(true)
      setProductName(postName)
      setImageFile(<ImageFile imgId={id} imageWidth={'200px'} />)
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  function calculateDisplayText(text, maxLength) {
    const length = Array.from(text).reduce((acc, char) => {
      return acc + (char.charCodeAt(0) > 255 ? 2 : 1);
    }, 0);

    if (length <= maxLength) {
      return text;
    } else {
      let displayLength = 0;
      let displayText = '';
      for (let i = 0; i < text.length; i++) {
        displayLength += text.charCodeAt(i) > 255 ? 2 : 1;
        if (displayLength > maxLength) {
          return displayText + '...';
        }
        displayText += text[i];
      }
      return displayText;
    }
  }

  const [flipClass, setFlipClass] = useState("");

  const handleBeforeChange = () => {
    // 切換前，加入翻轉效果的 class
    setFlipClass("flip");
  };

  const handleAfterChange = () => {
    // 切換完成後，移除翻轉效果的 class
    setFlipClass("");
  };
  return (
    <div >
      <Row justify="center">
        <Col xs={22} sm={22}>
          <Row >
            {/* 左边的大区域 */}
            <Col span={16}>
              <div >
                <Swiper
                  effect={'cube'}
                  // 設置滑動效果為 "cube"（立方體效果）
                  grabCursor={true}
                  // 啟用抓取游標樣式，讓滑動時顯示抓取效果
                  cubeEffect={{
                    shadow: true,
                    // 啟用立方體陰影效果
                    slideShadows: true,
                    // 啟用滑塊陰影效果，讓滑動更具層次感
                    shadowOffset: 5,
                    // 設置陰影偏移量
                    shadowScale: 0.94,
                    // 設置陰影的縮放比例，使陰影效果更逼真
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation
                  pagination={{ clickable: true }}
                  // 啟用分頁點，讓使用者可以點擊分頁點進行切換
                  modules={[Autoplay, EffectCube, Pagination, Navigation]}



                >
                  {/* 定義每一個 SwiperSlide，裡面包含要展示的圖片 */}
                  <SwiperSlide>
                    <img style={{ ...contentStyle, textAlign: 'left', backgroundSize: 'cover' }} src="/images/pandingpage/1.webp" alt="1" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img style={{ ...contentStyle, textAlign: 'left', backgroundSize: 'cover' }} src="/images/pandingpage/2.webp" alt="2" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img style={{ ...contentStyle, textAlign: 'left', backgroundSize: 'cover' }} src="/images/pandingpage/one1.jpg" alt="2" />
                  </SwiperSlide>

                </Swiper>

              </div>
            </Col>
            {/* 右边的小区域 */}
            <Col span={8}>
              <Row >
                <Col span={24}>
                  <div style={{ backgroundColor: '#1890ff', textAlign: 'center', height: '150px', margin: '5px' }}>

                    <Swiper
                      effect={'cube'}
                      // 設置滑動效果為 "cube"（立方體效果）
                      grabCursor={true}
                      // 啟用抓取游標樣式，讓滑動時顯示抓取效果
                      cubeEffect={{
                        shadow: true,
                        // 啟用立方體陰影效果
                        slideShadows: true,
                        // 啟用滑塊陰影效果，讓滑動更具層次感
                        shadowOffset: 5,
                        // 設置陰影偏移量
                        shadowScale: 0.94,
                        // 設置陰影的縮放比例，使陰影效果更逼真
                      }}
                      autoplay={{
                        delay: 9000,
                        disableOnInteraction: false,
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      // 啟用分頁點，讓使用者可以點擊分頁點進行切換
                      modules={[Autoplay, EffectCube, Pagination, Navigation]}



                    >
                      {/* 定義每一個 SwiperSlide，裡面包含要展示的圖片 */}
                      <SwiperSlide>
                        <div style={{ backgroundColor: '#faad14', textAlign: 'center', height: '150px', }}>
                          <img src="/images/pandingpage/two.png" alt="3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div style={{ backgroundColor: '#faad14', textAlign: 'center', height: '150px', }}>
                          <img src="/images/pandingpage/two1.png" alt="3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                      </SwiperSlide>


                    </Swiper>


                  </div>
                </Col>
                <Col span={24}>
                  <div style={{ backgroundColor: '#faad14', textAlign: 'center', height: '150px', margin: '5px' }}>
                    <Swiper
                      effect={'cube'}
                      // 設置滑動效果為 "cube"（立方體效果）
                      grabCursor={true}
                      // 啟用抓取游標樣式，讓滑動時顯示抓取效果
                      cubeEffect={{
                        shadow: true,
                        // 啟用立方體陰影效果
                        slideShadows: true,
                        // 啟用滑塊陰影效果，讓滑動更具層次感
                        shadowOffset: 5,
                        // 設置陰影偏移量
                        shadowScale: 0.94,
                        // 設置陰影的縮放比例，使陰影效果更逼真
                      }}
                      autoplay={{
                        delay: 7000,
                        disableOnInteraction: false,
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      // 啟用分頁點，讓使用者可以點擊分頁點進行切換
                      modules={[Autoplay, EffectCube, Pagination, Navigation]}



                    >
                      {/* 定義每一個 SwiperSlide，裡面包含要展示的圖片 */}
                      <SwiperSlide>
                        <div style={{ backgroundColor: '#faad14', textAlign: 'center', height: '150px', }}>
                          <img src="/images/pandingpage/three.png" alt="3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div style={{ backgroundColor: '#faad14', textAlign: 'center', height: '150px', }}>
                          <img src="/images/pandingpage/three1.png" alt="3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div style={{ backgroundColor: '#faad14', textAlign: 'center', height: '150px', }}>
                          <img src="/images/pandingpage/three2.png" alt="3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                      </SwiperSlide>


                    </Swiper>



                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row >


      <Row justify="center" style={{ margin: 20 }}>
        <Col xs={24} sm={24} md={18}>
          <p style={{
            fontSize: 32,
            color: 'black',
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#34374D'
          }}>最新上架熱門商品</p>
          <br />
          <Row gutter={20}>
            <Col xs={12} sm={12} md={8}>
              <Card


                bordered={false}

              >


                <div >
                  <p style={{ fontSize: '28px', color: '#181e4b' }}>

                    全宇宙獨有的競標現金

                  </p>
                  <p style={{ marginBottom: '10px', fontSize: '28px', color: '#181e4b' }}>


                    Bring Your Stuff To Cash

                  </p>
                  <span style={{ marginBottom: '10px', fontSize: '18px', color: '#5e6282' }} >
                    快點準備好您身邊用不到的東西，或許正好有個人想要
                    用現金跟您換呢? 何不馬上來試試?

                  </span>
                  <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', whiteSpace: 'nowrap', margin: '5px' }}>
                    <Button style={{ height: '40px', fontSize: '18px', background: 'rgb(250, 130, 50)', fontWeight: 'bold', borderColor: 'rgb(250, 130, 50)', color: '#181e4b' }}>
                      來去換現金
                    </Button>
                  </div>
                </div>


              </Card>
            </Col>
            {datas.slice(0, 10).map(item =>
              <Col xs={12} sm={12} md={4}>
                <Card
                  hoverable
                  style={{
                    marginBottom: 30,
                    position: 'relative',
                  }}
                  onClick={() => window.location.href = `/bnbarter/productdescribe/${item.postId}`} // Add this line
                  cover={<ImageFile imgId={item.images[0]} />}

                >

                  <Meta
                    title={<span style={{ fontSize: '18px', color: '#5e6282' }}>{item.postName}</span>}
                    description={
                      <div style={{ height: '40px', marginBottom: '10px', fontSize: '13px' }} >
                        <span>
                          {calculateDisplayText(item.prodDesc, 30)}
                        </span>
                      </div>
                    }
                  />

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <span style={{ color: '#2da5f3', fontSize: '14px', fontWeight: 'bold' }}>
                      Bid: {Math.floor(Math.random() * 100) + 1}人
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', whiteSpace: 'nowrap' }}>
                      <RobotOutlined
                        key="setting"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSettingClick(item.images[0], item.postName);
                        }}
                      />
                    </div>
                  </div></Card>
              </Col>
            )}
          </Row>
          <Row justify={'center'}>
            <Col span={24}>
              <a href='/bnbarter/productlist'>
                <div style={{ color: '#34374D', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 40, fontSize: 26, textDecoration: 'underline' }}>
                  查看更多商品
                </div>
              </a>
            </Col>
          </Row>
          <Modal
            title=""
            footer={null}
            onCancel={handleOk}
            open={isModalVisible}
          >
            {loading && (
              <>
                <div>AI分析估價中，請稍後...</div>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </>
            )}
            {result && (
              <>
                {imageFile}
                <Text style={{ fontSize: 14, whiteSpace: 'pre-line', }}>商品名稱:</Text>
                <Text style={{ fontSize: 14, whiteSpace: 'pre-line', }}>{productName}</Text>
                <Text style={{ fontSize: 14, whiteSpace: 'pre-line', }}><br />評估金額:</Text>
                < Text style={{ fontSize: 14, color: 'red', whiteSpace: 'pre-line' }}>{price.replace('[', '').replace(']', '')}</Text>
                < Text style={{ fontSize: 14, whiteSpace: 'pre-line' }}>{result.replace('>', '').replace('<', '').replace('[', '').replace(']', '').replace('[', '').replace(']', '')}</Text>
              </>
            )}
          </Modal>
        </Col>
      </Row >
    </div >
  )
}
export default LandingPage;
