import axios from 'axios';
import { useSelector } from 'react-redux'


const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

// http request interceptors

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.AccessControlAllowOrigin = '*'
  config.headers.AccessControlAllowHeaders = '*';
  config.headers.AccessControlAllowCredentials = true
  config.headers.Authorization = `Basic ${token}`;
  return config;
});

// http response interceptors

export default instance;
