import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from "jwt-decode";
import Base64 from 'base-64';
import moment from 'moment';
const initialState = {
    id: '',
    name: '',
    token: '',
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login: (state, action,) => {
            console.log('action', action)
            const user = jwtDecode(action.payload.token)
            const token = action.payload.token2
            state.token = token
            state.id = user.email
            state.name = user.name
            //state.token = user.email
            localStorage.setItem('token', token);
            localStorage.setItem('id', user.id);
            localStorage.setItem('name', user.name);
            localStorage.setItem('email', user.email);
        },
        logout: (state, action) => {
            state.id = ''
            state.name = ''
            state.token = ''
            localStorage.setItem('token', '');
            localStorage.setItem('id', '');
            localStorage.setItem('name', '');
            localStorage.setItem('email', '');
        },
        setToken: (state, action) => {
            state.id = action.id
            state.name = action.name
            state.token = action.token
            state.email = action.email
        }
    },
})

// Action creators are generated for each case reducer function
export const { login, logout, setToken } = loginSlice.actions

export default loginSlice.reducer