
import { Upload, message, Avatar, Dropdown, Breadcrumb, Layout, Menu, theme, Button, Checkbox, Form, Input, Row, Col, Select, Space, Badge } from 'antd';
import Icon, { createFromIconfontCN } from '@ant-design/icons';
import { FaLine, FaFacebook, FaLinkedin, FaInstagram, FaGoogle } from "react-icons/fa6";
import { Link, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { login, logout } from '../Login/loginSlice'
import { UserOutlined, FileSearchOutlined, CameraOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Basic.css';
import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const allOpetions = [
  {
    value: 1,
    label: "電器"
  },
  {
    value: 11190,
    label: "雜貨和美食"
  },
  {
    value: 12460,
    label: "健康與家居"
  },
  {
    value: 13337,
    label: "居家及廚房"
  },
  {
    value: 14785,
    label: "工業與科學"
  },
  {
    value: 16915,
    label: "電影和電視"
  },
  {
    value: 17624,
    label: "辦公用品"
  },
  {
    value: 18152,
    label: "庭院、草坪和花園"
  },
  {
    value: 18804,
    label: "寵物用品"
  },
  {
    value: 21454,
    label: "工具和家居裝修"
  },
  {
    value: 22618,
    label: "玩具和遊戲"
  },
  {
    value: 23181,
    label: "電玩遊戲"
  },
  {
    value: 2793,
    label: "嬰兒用品"
  },
  {
    value: 3114,
    label: "美容及個人護理"
  },
  {
    value: 3678,
    label: "圖書"
  },
  {
    value: 559,
    label: "汽車"
  },
  {
    value: 8721,
    label: "CD 和黑膠唱片"
  },
  {
    value: 9198,
    label: "手機及配件"
  },
  {
    value: 9277,
    label: "服裝、鞋子和珠寶"
  }
]

const options = [
  {
    value: "電器",
    label: "電器"
  },
  {
    value: "雜貨和美食",
    label: "雜貨和美食"
  },
  {
    value: "電影和電視",
    label: "電影和電視"
  },
  {
    value: "辦公用品",
    label: "辦公用品"
  },
  {
    value: "電玩遊戲",
    label: "電玩遊戲"
  },
  {
    value: "嬰兒用品",
    label: "嬰兒用品"
  },
  {
    value: "圖書",
    label: "圖書"
  }
]
  ;
const categories = [
  '通訊', '攝影', '影音', '電腦', '家居', '遊戲', '婦幼', '美妝', '時尚', '汽機車', '書籍', '戶外/露營', '新貨上架'
];
const Basic = () => {
  const language = 0
  const name = useSelector((state) => state.user.name);
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
    navigate('/login');
  };


  useEffect(() => {
    //!token && navigate('/login');

  }, []);

  const { Search } = Input;


  const settingMenu = (
    {
      items: [
        {
          key: 'personal-info',
          label: <a><i className="fa fa-sign-out" style={{ fontSize: 20 }} />&nbsp;{'個人資料'}</a>,
        },
        {
          key: 'message',
          label: <a><i className="fa fa-sign-out" style={{ fontSize: 20 }} />&nbsp;{'訊息通知'}</a>,
        }
        ,
        {
          key: 'logout',
          label: <a onClick={() => onLogout()}><i className="fa fa-sign-out" style={{ fontSize: 20 }} />&nbsp;{'登出'}</a>,
        },
      ],
    }
  );
  const getProdCond = async () => {
    try {
      //const res = await request.get(`/bnbarter/dropdown-list/${language}/prod_cond`);
      // setProdCond(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  };
  const props = {
    name: 'file',
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {

    },
  };

  return (
    <Layout className="layout-background">
      <Header className={`content-layout header-spacing`}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ width: '20%', display: 'flex', alignItems: 'center', }}>
            <Link to="/">
              <img
                src="/images/logobnbarter.png"
                alt="Logo"
                style={{
                  height: '130px',
                  float: 'left', // 圖片浮動在左側
                }}
              />
            </Link>
          </div>
          <div style={{ width: '40%', }}>
            <Space.Compact style={{ width: '100%' }}>
              <Input prefix={<FileSearchOutlined style={{ color: 'rgb(94, 98, 130)' }} />} placeholder="輸入商品名稱或描述" />
              <Upload {...props} fileList={[]}>
                <Button

                  icon={<CameraOutlined></CameraOutlined>}
                />
              </Upload>
            </Space.Compact>

          </div>
          {/* 1/3 Right Side */}
          <div style={{ width: '40%', textAlign: 'right' }}>
            <div >
              {!name ? (
                <>
                  <a href='/bnbarter/login' style={{ paddingLeft: 20, color: '#757575', textDecoration: 'underline' }}>登入</a>
                  <a href='/bnbarter/signup' style={{ paddingLeft: 20, color: '#757575', textDecoration: 'underline' }}>註冊</a>
                </>
              ) : (
                <>
                  <a href='/bnbarter/inventorylist' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>我的小庫房</a>
                  <a href='/bnbarter/salelist' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>商品上架</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>出貨管理</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>競標清單</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>收費與付款</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>購物車</a>
                  <Dropdown menu={settingMenu} trigger={['click']} placement="bottomRight">
                    <span style={{ paddingLeft: 20, color: 'rgba(0,0,0,.87)' }}>
                      <Badge count={1} >
                        <Avatar icon={<UserOutlined />} className="avatar" />
                      </Badge>
                      <span> {name}</span>
                    </span>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
      </Header>
      <Content className="content-layout"
        style={{
          backgroundColor: '#FFF', minHeight: '80vh'
        }}
      >

        <div style={{ backgroundColor: '#fff' }} >
          <Outlet />
        </div>
      </Content>
      <Footer className={`content-layout footer-spacing`}
        style={{
          background: '#fff'
        }}
      >
        <Row style={{ display: 'flex', alignItems: 'baseline' }}>
          <Col xs={22} sm={5} span={2}>

          </Col>
          <Col xs={22} sm={5} span={2}>
            <span className='footer-title'>bnbarter</span>
            <br />
            <br />
            <span className='footer-message'>All you can bid.</span>
          </Col>
          <Col xs={22} sm={4} span={2}>
            <span className='footer-second-title'>關於 bnbarter</span>
            <br />
            <br />
            <p className='footer-item'>bnbarter是什麼?</p>
            <p className='footer-item'>bnbarter政策條款</p>
            <p className='footer-item'>隱私權政策</p>
            <p className='footer-item'>會員小庫房</p>
            <p className='footer-item'>條惠特急標</p>
          </Col>
          <Col xs={22} sm={2} span={2}>
            <span className='footer-second-title'>服務中心</span>
            <br />
            <br />
            <p className='footer-item'>網站小幫手?</p>
            <p className='footer-item'>交易競標方式</p>
            <p className='footer-item'>b幣錢包</p>
            <p className='footer-item'>運費補助</p>
            <p className='footer-item'>退貨退款</p>
            <p className='footer-item'>聯絡客服</p>

          </Col>
          <Col xs={22} sm={4} span={2}>
            <span className='footer-second-title'></span>
            <br />
            <br />

            <p className='footer-item'>改善建議</p>
            <p className='footer-item'>防詐騙說明</p>
          </Col>
          <Col xs={22} sm={3} span={2}>
            <span className='footer-second-title'>關注我們</span>
            <br />
            <br />
            <div style={{ display: 'inline-block' }}>
              <FaFacebook style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
              <FaInstagram style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
              <FaLine style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
              <FaLinkedin style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
            </div>
          </Col>


        </Row>
        <Row justify="center">
          <Col xs={24} sm={24} span={24} style={{ textAlign: 'center', color: '#5e6282', margin: 20, fontFamily: 'Poppins' }}>
            版權 2023-2024 Rocker Technology Ltd. 本公司保留所有權利。會員合約、私隱權通知、支付使用款、及 Cookie.
          </Col>
        </Row>


      </Footer>
    </Layout >

  )
}
export default Basic
