
import { PlusOutlined, UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Avatar, Table, Card, Pagination, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MapPin } from '@tabler/icons-react';
import Modal from 'antd/es/modal/Modal';

const SaleBidList = () => {
  const columns = [
    {
      title: '物件名稱',
      dataIndex: 'name',
      render: (text, record, index) => (
        <><sanp> <Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}></Avatar>{text}</sanp>

        </>
      )
    },
    {
      title: '估計價格',
      dataIndex: 'quantity',

      render: (text, record) => (
        <><p>競標商品X{text}</p>
          <p >估值{record.price}</p>
        </>
      )
    },
    {
      title: '',
      dataIndex: 'image',

      render: (text, record) => (

        <>
          {text.map((item, index) =>

            <img src={`/images/bidlist/${item}`} onClick={() => showModal(record.prodName[index], record.prodPrice[index])} style={{ height: '80px', height: '80px', float: 'left', margin: 10 }} ></img>


          )}
        </>

      )
    },



    {
      title: '',
      dataIndex: 'operation',
      key: 'action',
      render: (_, record) =>
      (
        <Button type='primary' danger>RESERVE</Button>
      )
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },

  }
  const data = [
    {
      key: '1', name: '2項物品 ', quantity: 1,
      price: '$9980', bid: 3, bidOther: 20, cash: 'No', date: '2024/01/05', photo: '1.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '2', name: '現金物件', quantity: 2,
      price: '$1000', bid: 20, bidOther: 30, cash: 'No', date: '2024/01/06',
      photo: '2.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '3', name: '滑鼠', quantity: 1, price: '$399',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '3.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '4', name: '星巴克禮卷', quantity: 1, price: '$500',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '4.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '5', name: '籃球', quantity: 1, price: '$200',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '5.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '6', name: '手鍊', quantity: 1, price: '$999',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '6.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '7', name: '玩具', quantity: 1, price: '$500',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '7.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '8', name: '盆栽', quantity: 1, price: '$200',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '8.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '9', name: 'usb充電線', quantity: 1, price: '$100',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '9.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '10', name: '腳踏車', quantity: 1, price: '$1500',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '10.png', pay: '付費行銷', w: '50', g: '50'
    },
    {
      key: '11', name: '抹布', quantity: 1, price: '$100',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '11.png', pay: '付費行銷', w: '50', g: '50'
    },


  ];
  const bidData = [
    {
      key: '1', name: 'Will', quantity: 7,
      price: '$2350', image: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png'],
      prodName: ['護膝', 'MIRAE 未來美 EX8分鐘逆時空膠囊面膜1盒(保濕/亮白/修護)', '超濃代謝夜酵素錠EX30顆', '葉黃素', '滑版車', '安全帽', '腳踏車'],
      prodPrice: ['200', '300', '900', '100', '200', '300', '600']
    },
    {
      key: '2', name: 'Jay', quantity: 3,
      price: '$2350', image: ['8.png', '9.png', '10.png'],
      prodName: ['維它命c', '葉黃素', '滑版車'],
      prodPrice: ['200', '300', '900', '100', '200', '300', '600']
    },
    {
      key: '3', name: 'John', quantity: 4,
      price: '$2350', image: ['11.png', '12.png', '13.png', '14.png'],
      prodName: ['安全帽', '腳踏車', '抹布', '隨身攜帶小暖爐'],
      prodPrice: ['200', '300', '900', '100', '200', '300', '600']
    },
  ]

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // 设置每页默认显示的数量
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = data.slice(startIndex, endIndex);
  const [modalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const showModal = (name, price) => {
    setName(name)
    setPrice(price)
    setModalVisible(true);
  };
  const handleModalCancel = () => {
    setModalVisible(false);

  };


  return (
    <div className='bordered-div' style={{ width: '90%' }}>

      <Row gutter={30}>
        <Col xs={24} sm={24}>
          <div style={{ margin: 10, fontSize: 18, }}>
            <Button><span style={{ fontSize: 18, textDecoration: 'underline' }}>上架商品</span></Button>
            <span style={{ margin: 10, fontSize: 18, textDecoration: 'underline' }}>競標中</span>
            <span style={{ margin: 10, fontSize: 18, textDecoration: 'underline' }}>已結標</span>
            <span style={{ margin: 10, fontSize: 18, textDecoration: 'underline' }}>交易完成</span>
            數量:6件
          </div>

        </Col>
      </Row>
      <Modal footer={null}
        open={modalVisible}
        onCancel={handleModalCancel}

      >
        <Row gutter={30}>
          <Col xs={10} sm={10}>
            上架商品名稱:
          </Col>
          <Col xs={12} sm={12}>
            {name}
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={10} sm={10}>
            預估售價:
          </Col>
          <Col xs={12} sm={12}>
            TW${price}
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={10} sm={10}>
            出貨時間(預設):
          </Col>
          <Col xs={12} sm={12}>
            3-5(工作)天
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={10} sm={10}>
            商品型號:
          </Col>
          <Col xs={12} sm={12}>
            xx-xx
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={10} sm={10}>
            盒/單狀況:
          </Col>
          <Col xs={12} sm={12}>
            都在
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={10} sm={10}>
            商品產地:
          </Col>
          <Col xs={12} sm={12}>
            台灣
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={10} sm={10}>
            商品重量:
          </Col>
          <Col xs={12} sm={12}>
            500克
          </Col>
        </Row>
      </Modal>

      <Row gutter={30}>
        <Col xs={12} sm={12}>
          <div style={{ margin: 10, fontSize: 14 }}>
            <span>{data.length}件上架商品(尚可新增80件商品)</span>
          </div>
        </Col>
        <Col xs={12} sm={12} offset={{ xs: 0, sm: 12 }}>

        </Col>
      </Row>
      <Row gutter={24} justify="center">
        {data.slice(0, 6).map(item =>
          <Col xs={4} sm={4}>
            <Card >

              <img src={`/images/inventory/${item.photo}`}
                alt="Logo"
                style={{ height: '130px' }}
              ></img><p>{item.name} </p><p> 競標:{item.bid}   bid:{item.bidOther}</p>
            </Card>
          </Col>
        )}


      </Row >
      <Row gutter={24} justify="center">
        <Col xs={24} sm={24}>
          <div style={{ display: 'flex', alignItems: 'center', }}>
            <Card style={{ width: '100%' }}>
              <Table
                showHeader={false}
                columns={columns}
                dataSource={bidData}
                pagination={false}
              />


            </Card>
          </div >
        </Col>
      </Row>

    </div >
  )





}
export default SaleBidList
