import { Cascader, Modal, Flex, message, Upload, Divider, Table, Select, Card, Pagination, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { LoadingOutlined, PlusOutlined, FileImageOutlined } from '@ant-design/icons';
import request from '../../requests/api';
import { useSelector, useDispatch } from 'react-redux';
import './Inventory.css';
import prodCategoryDatas from '../../../src/assets/data/prodCategories.json';//導入 JSON 文件

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const NewInventory = () => {

  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const token = useSelector((state) => state.user.token);
  const [skuKey, setSkuKey] = useState('');
  const [selectProdCategories, setSelectProdCategories] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const { skuId, url } = useParams();//使用useParams获取路由参数
  const handleCancel = () => setPreviewOpen(false);
  const [options, setOptions] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const navigate = useNavigate();

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        新增商品照片
      </div>
    </button>
  );
  const { TextArea } = Input;
  const onChange = (value) => {
    console.log(value);
  };
  const [imageFileDatas, setImageFileDatas] = useState(null)
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [form] = Form.useForm();
  const [prodCond, setProdCond] = useState([]);
  const [selectProdCond, setSelectProdCond] = useState('');
  const [warrantyStatus, setWarrantyStatus] = useState([]);
  const [purTime, setPurTime] = useState([]);
  const [warrantyPeriod, setWarrantyPeriod] = useState([]);
  const [prodCategories, setProdCategories] = useState([]);
  const language = 0;
  const location = useLocation();
  const { record, mode } = location.state || {};
  const getProdCategories = async () => {
    try {
      const res = await request.get(`/bnbarter/prod-categories/${language}/0`);
      setProdCategories(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  };

  function buildOptions(data, parentId = "0") {
    return data
      .filter(item => item.parentCateId === parentId)
      .map(item => ({
        value: item.id,
        label: item.name,
        children: buildOptions(data, item.id)
      }));
  }

  function findAllChildren(data, parentId) {
    let result = [];

    function findChildren(data, parentId) {
      data.forEach(item => {
        if (item.id === parentId && item.parentCateId === '0') {
          result.push(item);
        }
        if (item.parentCateId === parentId) {
          result.push(item);
          findChildren(data, item.id, '');//遞歸查找子項目
        }
      });
    }

    findChildren(data, parentId);
    return result;
  }

  useEffect(() => {
    if (mode != 'add') {
      if (record) {
        onProdChange(record.prodCond)
        form.setFieldsValue(record);
        form.setFieldsValue({ prodCategories: record.prodCategories.split(",").map(item => item.trim()) });
        setFileList(record.images || []);
        setSkuKey(record.skuId);
        fetchImage(record.skuId)
      }
    }
  }, [record]);
  const fetchImage = async (skuId) => {
    try {
      const res = await request.get(`/bnbarter/mem-inventory-image/${skuId}`);
      if (res.data.datas) {
        setFileList(res.data.datas.map(file => ({
          uid: file.imgId,//unique identifier for the file
          name: file.imgName,//file name
          status: 'done',//file status ('done' means the file is successfully uploaded)
          url: `${process.env.REACT_APP_API_URL}/bnbarter/mem-inventory-image1/${file.imgId}`,
          skuId: file.skuId,//additional custom field if needed
          imgAutoFeaturesJson: file.imgAutoFeaturesJson,//additional custom field if needed
          remark: file.remark,//additional custom field if needed
          updateTmsp: file.updateTmsp,//additional custom field if needed
          modifier: file.modifier//additional custom field if needed
        })))
      }

    } catch (err) {
      console.error(err);
    }
  };


  const loadData = async () => {
    if (skuId) {
      try {
        const res = await request.get(`/bnbarter/mem-inventory/${skuId}`);
        const data = res.data.datas;
        form.setFieldsValue(data);
        setFileList(data.images || []);
        setSkuKey(data.skuKey);
        //Other data initializations as needed
      } catch (error) {
        console.error('Error fetching inventory data:', error);
      }
    }
  };

  useEffect(() => {
    getSkuKey();
    getProdCond();
    getWarrantyStatus();
    getPurTime();
    getWarrantyPeriod();
    getProdCategories();
    loadData();//加载初始数据
  }, [skuId]);

  const onProdChange = async (value) => {
    form.setFieldsValue({ prodCategories: '' });
    setSubCategories('');
    setSelectProdCond(value);
    setOptions([]);
    try {
      const newProdDatas = findAllChildren(prodCategoryDatas, value);
      const formattedOptions = buildOptions(newProdDatas);
      setOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching product categories:', error);
    }
  };

  const getWarrantyPeriod = async () => {
    try {
      const res = await request.get(`/bnbarter/dropdown-list/${language}/warranty_period`);
      setWarrantyPeriod(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  };

  const getPurTime = async () => {
    try {
      const res = await request.get(`/bnbarter/dropdown-list/${language}/pur_time`);
      setPurTime(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  };

  const getWarrantyStatus = async () => {
    try {
      const res = await request.get(`/bnbarter/dropdown-list/${language}/warranty_status`);
      setWarrantyStatus(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  };

  const getProdCond = async () => {
    try {
      const res = await request.get(`/bnbarter/dropdown-list/${language}/prod_cond`);
      setProdCond(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalOk = () => {
    setModalVisible(false);
    form.setFieldsValue({ 'prodCategories': selectProdCategories });
    form.setFieldsValue({ 'prodCategoriesName': selectedOption.map(option => option.label).join('>') });
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const getSkuKey = async () => {
    try {
      const res = await request.get('/bnbarter/sku-key');
      setSkuKey(res.data.skuId);
    } catch (error) {
      console.log(error);
    }
  };

  const onCaChange = async (selectedIds, selectedOption) => {
    const formattedString = selectedIds.join(',');
    setSelectProdCategories(formattedString);
    setSelectedOption(selectedOption);

    const selectedId = selectedIds[selectedIds.length - 1];//取得最後一層的選擇ID
    if (!selectedId) return;//如果沒有選擇ID，則不執行任何操作

    await request.get(`/bnbarter/prod - categories/0/${selectedId}`)
      .then(response => {
        const newChildren = response.data.datas.map(option => ({
          value: option.id,
          label: option.name,
          children: []//新的選項將作為子選項
        }));

        const updatedOptions = updateOptions(options, selectedIds, newChildren);
        setOptions(updatedOptions);//更新選項狀態
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });
  };

  //遞迴更新選項
  const updateOptions = (options, selectedIds, newChildren) => {
    if (selectedIds.length === 1) {
      //如果只有一個選擇ID，直接更新第一層選項
      return options.map(option => {
        if (option.value === selectedIds[0]) {
          return {
            ...option,
            children: newChildren
          };
        }
        return option;
      });
    } else {
      //如果有多個選擇ID，則遞迴更新子選項
      return options.map(option => {
        if (option.value === selectedIds[0]) {
          return {
            ...option,
            children: updateOptions(option.children, selectedIds.slice(1), newChildren)
          };
        }
        return option;
      });
    }
  };

  const props = {
    action: `${process.env.REACT_APP_API_URL}/bnbarter/mem-inventory-image/${skuKey}`,
    multiple: false,
    headers: {
      authorization: `Basic ${token}`,
    },
    onChange({ file, fileList }) {
      setFileList(fileList);

      if (file.status === 'done') {
        const res = file.response;
        if (res.code === 1) {
          Modal.success({
            content: '上傳成功',
          });
        } else {
          setFileList([]);
          Modal.error({
            title: '上傳失敗',
            content: res.datas,
          });
        }
      }
    },
  };

  const onSaveMemInventory = async (values) => {
    console.log(values['prodCategories'], values['prodCategories'])
    values['prodCategories'] = values['prodCategories'].join(', ')
    if (mode === 'edit') {
      //编辑模式
      await request.put(`/bnbarter/mem-inventory/${skuId}`, { ...values })
        .then((res) => {
          if (res.data.code === 1) {
            Modal.success({
              content: '資料更新成功',
            });
            navigate('/bnbarter/inventorylist');
          } else {
            Modal.error({
              title: '資料更新失敗',
              content: res.data.msg,
            });
          }
        });
    } else {
      //新增模式
      await request.post(`/bnbarter/mem-inventory/${skuKey}`, { ...values })
        .then((res) => {
          if (res.data.code === 1) {
            Modal.success({
              content: '資料新增成功',
            });
            navigate('/bnbarter/inventorylist');
          } else {
            Modal.error({
              title: '資料新增失敗',
              content: res.data.msg,
            });
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='bordered-div' style={{ width: '75%' }}>
      <Row>
        <Col sm={18} xs={24}>
          <span className='inventory-title'>{skuId ? '編輯商品' : '新增商品'}</span>
        </Col>
      </Row>
      <Row justify={'center'}>
        <Col sm={18} xs={24}>
          <Form
            form={form}
            onFinish={onSaveMemInventory}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="商品圖片"
              name="productName"
            >
              <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item
              label="商品名稱"
              name="prodName"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input showCount maxLength={80} />
            </Form.Item>

            <Space>
              <Form.Item
                label="金額"
                name="price"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label="數量"
                name="qty"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Space>

            <Form.Item
              label="商品大分類"
              name="prodCond"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                onChange={onProdChange}
                style={{ width: 250 }}
                options={prodCategories.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              label="商品小分類"
              name="prodCategories"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Cascader disabled={selectProdCond === ''} options={options} />
            </Form.Item>

            <Form.Item
              label="商品描述"
              name="prodDesc"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <TextArea
                showCount
                maxLength={2000}
                style={{
                  height: 120,
                  resize: 'none',
                }}
              />
            </Form.Item>

            <span style={{ margin: 10, fontSize: 16, textDecoration: 'underline' }}>AI生成商品描述</span>



            <Form.Item
              label="保固狀態"
              name="warrantyStatus"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                style={{ width: 120 }}
                options={warrantyStatus.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              label="保固年限"
              name="warrantyPeriod"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                style={{ width: 120 }}
                options={warrantyPeriod.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              label="購入時間"
              name="purTime"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                options={purTime.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>

            <Button style={{ margin: 10 }} onClick={() => navigate('/bnbarter/inventorylist')}>取消</Button>
            <Button style={{ margin: 10 }} htmlType="submit" type='primary' danger>儲存入庫</Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default NewInventory;
