import React, { useEffect } from 'react';
import request from '../../requests/api';

const ImageFileBlob = ({ imgId }) => {
    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const res = await request.get(`/bnbarter/mem-inventory-image/${imgId}`, {
                    responseType: 'arraybuffer',
                });
                return res.data;
            } catch (err) {
                console.error(err);
                return null;
            }
        };

        fetchImageData();
    }, [imgId]);

    return null; // 這個元件不需要渲染任何內容
};

export const getImageBinaryData = async (imgId) => {
    try {
        const res = await request.get(`/bnbarter/mem-inventory-image/${imgId}`, {
            responseType: 'arraybuffer',
        });
        return res.data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default ImageFileBlob;
