import { configureStore } from '@reduxjs/toolkit'
import loginReducer from '../component/Login/loginSlice';


export const store = configureStore({
    reducer: {
        user: loginReducer,
    },
})

const user = store.getState().user
if (user.token === '') {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');
    const email = localStorage.getItem('email');

    if (token) {
        console.log('login/setToken')
        store.dispatch({
            type: 'login/setToken',
            token: token,
            name: name,
            id: id,
            email: email
        })
    }
}

