
import { Avatar, Dropdown, Breadcrumb, Layout, Menu, theme, Button, Checkbox, Form, Input, Row, Col, Select, Space, Badge } from 'antd';
import Icon, { FacebookFilled, InstagramFilled, LineHeightOutlined, LinkedinFilled, createFromIconfontCN } from '@ant-design/icons';
import { FaLine, FaFacebook, FaLinkedin, FaInstagram, FaGoogle } from "react-icons/fa6";
import { Link, Outlet } from 'react-router-dom';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { login, logout } from '../Login/loginSlice'
import { BarChartOutlined, UserOutlined, UploadOutlined, VideoCameraOutlined, MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Basic.css'
const { Header, Content, Footer } = Layout;
const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js',
    // icon-javascript, icon-java, icon-shoppingcart (overridden)
    '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
  ],
});

const options = [
  {
    value: '服飾',
    label: '服飾',
  },
  {
    value: '內衣',
    label: '內衣',
  },
  {
    value: '精品/錶',
    label: '精品/錶',
  },
  {
    value: '鞋子',
    label: '鞋子',
  },
  {
    value: '包包',
    label: '包包',
  },
  {
    value: '美妝保養',
    label: '美妝保養',
  },
  {
    value: '食品',
    label: '食品',
  },
];
const BasicInventory = () => {
  const name = useSelector((state) => state.user.name);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
    navigate('/login');
  };

  const settingMenu = (
    {
      items: [
        {
          key: 'personal-info',
          label: <a><i className="fa fa-sign-out" style={{ fontSize: 20 }} />&nbsp;{'個人資料'}</a>,
        },
        {
          key: 'message',
          label: <a><i className="fa fa-sign-out" style={{ fontSize: 20 }} />&nbsp;{'訊息通知'}</a>,
        }
        ,
        {
          key: 'logout',
          label: <a onClick={() => onLogout()}><i className="fa fa-sign-out" style={{ fontSize: 20 }} />&nbsp;{'登出'}</a>,
        },
      ],
    }
  );
  return (
    <Layout className="layout-background">
      <Header className={`content-layout header-spacing`}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ width: '33%', display: 'flex', alignItems: 'center', }}>
            <Link to="/">
              <img
                src="/images/logobnbarter.png"
                alt="Logo"
                style={{
                  height: '130px',
                  float: 'left', // 圖片浮動在左側
                }}
              />
            </Link>
            <span style={{ fontSize: 28 }}>我的小庫房</span>
          </div>
          {/* 1/3 Middle */}
          <div style={{ width: '33%', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
            <span style={{ margin: 10 }}>庫存品項查找(%)</span>
            <Input style={{ width: '60%' }} />
            <Button type='primary'>快速搜尋</Button>
          </div>





          {/* 1/3 Right Side */}
          <div style={{ width: '40%', textAlign: 'right' }}>
            <div >
              {!name ? (
                <a href='/bnbarter/login' style={{ paddingLeft: 20, textDecoration: 'underline' }}>登入</a>
              ) : (
                <>
                  <a href='/bnbarter/inventorylist' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>我的小庫房</a>
                  <a href='/bnbarter/salelist' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>商品上架</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>出貨管理</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>競標清單</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>收費與付款</a>
                  <a href='' style={{ paddingLeft: 10, color: '#757575', textDecoration: 'underline' }}>購物車</a>
                  <Dropdown menu={settingMenu} trigger={['click']} placement="bottomRight">
                    <span style={{ paddingLeft: 20, color: 'rgba(0,0,0,.87)' }}>
                      <Badge count={1} >
                        <Avatar icon={<UserOutlined />} className="avatar" />
                      </Badge>
                      <span> {name}</span>
                    </span>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
      </Header>

      <Content className="content-layout"
        style={{
          backgroundColor: '#FFF', minHeight: '80vh'
        }}
      >

        <div style={{ backgroundColor: '#fff' }} >
          <Outlet />
        </div>
      </Content>

    </Layout >

  )
}
export default BasicInventory
