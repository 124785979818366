

import { Breadcrumb, Modal, Layout, Menu, theme, Button, Checkbox, Form, Input, Row, Col, Space } from 'antd';
import Icon, { FacebookFilled, InstagramFilled, LineHeightOutlined, LinkedinFilled, createFromIconfontCN } from '@ant-design/icons';
import { FaLine, FaFacebook, FaLinkedin, FaInstagram, FaGoogle } from "react-icons/fa6";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { Link, Outlet } from 'react-router-dom';
import { setToken } from './loginSlice'
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect, } from 'react';
import { jwtDecode } from "jwt-decode";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { login, logout } from './loginSlice'
import Base64 from 'base-64';
import request from '../../requests/auth'
import '../../assets/styles/Login.css';
const { Header, Content, Footer } = Layout;

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js',
        // icon-javascript, icon-java, icon-shoppingcart (overridden)
        '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
    ],
});


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isCheckLogin, setIsCheckLogin] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isSmsLogin, setIsSmsLogin] = useState(false);



    const handleSmsLogin = () => {
        setIsSmsLogin(true);
        setIsCheckLogin(false)
    };

    const getSendVerifySms = async (phoneNumber) => {
        try {
            const res = await request.post(`auth/send-verify-sms/${phoneNumber}`);
        } catch (error) {
            console.log(error)
        }
    }
    const validatePhone = (rule, value, callback) => {
        const reg = /^09[0-9]{8}$/;
        if (value && !reg.test(value)) {
            callback('請輸入有效的手機號碼！');
        } else {
            callback();
        }
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleSendVerificationCode = () => {
        // Here you can implement the logic to send verification code to the phone number
        // After sending the code, you might want to close the modal or show some feedback to the user
    };
    const responseFacebook = (response) => {
        console.log('fb response', response);
        if (checkEamil(response.email) === 'Y') {
            navigate('/bnbarter/main')

        } else {
            navigate(`/bnbarter/signup?email=${response.email}&name=${response.name}`)
        }
        //alert('驗 證  fb 成 功 email: ' + response.email + '  姓    名:' + response.name)
        //dispatch(login(response))
        //navigate('/bnbarter/main')

    }
    const onFinish = (values) => {
        if (isSmsLogin) {
            getSendVerifySms(values['phoneNumber'])
            setPhoneNumber(values['phoneNumber']);
            setIsSmsLogin(false);
            setIsCheckLogin(true)

        } else if (isCheckLogin) {
            getSmsLogin(values['verifyCode'])
        }

        else {
            const username = values['username']
            const password = values['password']

            const loginInfo = Base64.encode(`${username}:${password}`);
            authLogin(loginInfo)
        }

    };
    const authLogin = async (loginInfo) => {
        //navigate('/startpage');
        await request.get('/auth/token', {
            headers: {
                Authorization: `Basic ${loginInfo}`,
            },
        }).then((res) => {
            const token = res.data.token
            console.log('token', res.data)
            dispatch(login({ token: token, token2: res.data.token2 }))
            navigate('/bnbarter/main')
        }).catch((error) => {
            console.log(error)
            Modal.error({
                title: '登入失敗',
                content: '無效的帳號或密碼，請重新輸入。',
            });
        })
    }

    const loginGoogle = useGoogleLogin({
        onSuccess: async (credentialResponse) => {
            try {
                const token = credentialResponse.access_token
                if (token) {
                    await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json`, { params: { access_token: token } })
                        .then((res) => {
                            if (checkEamil(res.data.email) === 'Y') {
                                navigate('/bnbarter/main')

                            } else {
                                navigate(`/bnbarter/signup?email=${res.data.email}&name=${res.data.name}`)
                            }
                        })

                }

            } catch (error) {
                alert('登入失敗')
            }

        }
    });

    const checkEamil = async (mail) => {
        await request.get(`/auth/is-valid-user/${mail}`, {
        }).then((res) => {
            console.log(res.data)
            return res.data.data
        }).catch((error) => {
            console.log(error)
        })
    }


    const getSmsLogin = async (verifyCode) => {
        try {
            const res = await request.post(`/auth/login-sms/${phoneNumber}`, { verifyCode: verifyCode });
            const token = res.data.token
            console.log(token)
            if (token) {
                dispatch(login(token))
                navigate('/bnbarter/main')

            } else {
                Modal.error({
                    title: '錯誤',
                    content: '驗證碼輸入錯誤'
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    const calculateTimeLeft = () => {
        const currentYear = new Date().getFullYear();
        const targetDate = new Date(`December 25, ${currentYear} 00:00:00`);
        const now = new Date();
        const difference = targetDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {

                '天': Math.floor(difference / (1000 * 60 * 60 * 24)),

            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]} {interval}{' '}
            </span>
        );
    });


    return (
        <Layout className="layout-background">
            <Header className={`content-layout header-spacing`}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to="/">
                        <img
                            src="/images/logobnbarter.png"
                            alt="Logo"
                            style={{
                                height: '130px',
                                float: 'left', // 圖片浮動在左側
                            }}
                        />
                    </Link>

                    <span style={{ paddingLeft: 40, color: 'red', fontSize: 14, textDecoration: 'underline', }}>網站小幫手 </span>
                </div>
            </Header>
            <Content className="content-layout login-content-layout "

            >

                <Row align="middle">
                    <Col xs={0} sm={1}></Col>
                    <Col xs={22} sm={18}
                        style={{
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                            borderRadius: '10px',
                            padding: '20px',
                            borderTop: 'none',  // 取消顶部边框
                            margin: 'auto',
                        }}>
                        <Row justify="center" align="top">
                            <Col sm={8} >
                                <Form
                                    onFinish={onFinish}
                                    style={{ margin: 10 }}
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{
                                        remember: true,
                                    }}
                                >
                                    <span className='welcome-text'> Welcome, 歡迎!</span>

                                    {!isSmsLogin && !isCheckLogin &&
                                        (<>< Form.Item
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '請輸入電話號碼/Email',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='bnbarter888@gmail.com' />
                                        </Form.Item>

                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '請輸入密碼',
                                                    },
                                                ]}
                                            >
                                                <Input.Password placeholder='1234' />
                                            </Form.Item>
                                            <Form.Item className='login-text'>
                                                <span>
                                                    <a>忘記密碼</a> <a onClick={handleSmsLogin}>簡訊登入</a>
                                                </span>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button htmlType="submit" className='login-button'>
                                                    登入
                                                </Button>
                                            </Form.Item>
                                        </>
                                        )

                                    }
                                    {isSmsLogin && (
                                        <>
                                            <Form.Item
                                                name="phoneNumber"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '請輸入行動電話號碼',
                                                    },
                                                    { validator: validatePhone },
                                                ]}
                                            >
                                                <Input placeholder='請輸入手機號碼' />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button htmlType="submit" className='login-button'>
                                                    下一步
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}

                                    {isCheckLogin && (
                                        <>
                                            <div align='middle' style={{ marginTop: 110, marginBottom: 70 }}>
                                                <Col  >
                                                    <p className='code-text'>輸入驗證碼</p>
                                                    <p className='phone-message-text'>簡訊已送到: {phoneNumber}</p>
                                                </Col>
                                            </div>
                                            <Form.Item name="verifyCode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '請輸入驗證碼',
                                                    },

                                                ]}
                                            >
                                                <Input.OTP className='phone-input' length={6} />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button htmlType="submit" className='login-button'>
                                                    下一步
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}


                                    <Form.Item>
                                        <Button onClick={() => navigate('/bnbarter/signup')} className='sign-button'>
                                            新朋友註冊
                                        </Button>
                                    </Form.Item>

                                    <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                        <Space>
                                            <Button onClick={loginGoogle} icon={<FaGoogle style={{ fontSize: 20, color: 'red' }} />}>
                                                google登入
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col xs={0} sm={2} >
                            </Col>
                            <Col xs={0} sm={12} >

                                <p style={{ fontWeight: 'bold', color: '#383a4a', textAlign: 'center', fontSize: '23px', marginTop: 20 }}>Super chill 多元交易社群平台</p>
                                <p style={{ fontWeight: 'bold', color: '#383a4a', textAlign: 'center', fontSize: '23px', marginTop: 10 }}>預計2024.12.25 光榮開測 </p>
                                <p style={{ fontWeight: 'bold', color: '#383a4a', textAlign: 'center', fontSize: '32px', marginTop: 10 }}>還有{timerComponents.length ? timerComponents : <span>Time's up!</span>}</p>
                                <p style={{ fontWeight: 'bold', color: '#383a4a', textAlign: 'center', fontSize: '23px', marginTop: 10 }}>讓家裡用不到的東西 垃圾變黃金!</p>
                                <p style={{ fontWeight: 'bold', color: '#383a4a', textAlign: 'center', fontSize: '23px', marginTop: 10 }}>讓斷捨離變得更自在</p>
                                <p style={{ fontWeight: 'bold', color: '#383a4a', textAlign: 'center', fontSize: '23px', marginTop: 30 }}>
                                    <img src='/images/login/people.png' style={{ display: 'block', margin: '0 auto', width: '237px', height: '207px' }} />
                                </p>

                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Content >

            <Footer className={`content-layout footer-spacing`}
                style={{
                    background: '#fff'
                }}
            >
                <Row style={{ display: 'flex', alignItems: 'baseline', marginTop: '100px' }}>
                    <Col xs={22} sm={5} span={2}>

                    </Col>
                    <Col xs={22} sm={5} span={2}>
                        <span className='footer-title'>bnbarter</span>
                        <br />
                        <br />
                        <span className='footer-message'>All you can bid.</span>
                    </Col>
                    <Col xs={22} sm={4} span={2}>
                        <span className='footer-second-title'>關於 bnbarter</span>
                        <br />
                        <br />
                        <p className='footer-item'>bnbarter是什麼?</p>
                        <p className='footer-item'>bnbarter政策條款</p>
                        <p className='footer-item'>隱私權政策</p>
                        <p className='footer-item'>會員小庫房</p>
                        <p className='footer-item'>條惠特急標</p>
                    </Col>
                    <Col xs={22} sm={2} span={2}>
                        <span className='footer-second-title'>服務中心</span>
                        <br />
                        <br />
                        <p className='footer-item'>網站小幫手?</p>
                        <p className='footer-item'>交易競標方式</p>
                        <p className='footer-item'>b幣錢包</p>
                        <p className='footer-item'>運費補助</p>
                        <p className='footer-item'>退貨退款</p>
                        <p className='footer-item'>聯絡客服</p>

                    </Col>
                    <Col xs={22} sm={4} span={2}>
                        <span className='footer-second-title'></span>
                        <br />
                        <br />

                        <p className='footer-item'>改善建議</p>
                        <p className='footer-item'>防詐騙說明</p>
                    </Col>
                    <Col xs={22} sm={3} span={2}>
                        <span className='footer-second-title'>關注我們</span>
                        <br />
                        <br />
                        <div style={{ display: 'inline-block' }}>
                            <FaFacebook style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
                            <FaInstagram style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
                            <FaLine style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
                            <FaLinkedin style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
                        </div>
                    </Col>


                </Row>
                <Row justify="center">
                    <Col xs={24} sm={24} span={24} style={{ textAlign: 'center', color: '#5e6282', margin: 20, fontFamily: 'Poppins' }}>
                        版權 2023-2024 Rocker Technology Ltd. 本公司保留所有權利。會員合約、私隱權通知、支付使用款、及 Cookie.
                    </Col>
                </Row>


            </Footer>
        </Layout >
    );
}

export default Login;
