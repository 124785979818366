
import { Routes, Route, } from "react-router-dom";

import React, { useState, useEffect } from 'react';
import Login from "./component/Login/Login";
import Basic from "./component/Layout/Basic";
import SignUp from "./component/SignUp/SignUp";
import LandingPage from "./component/Landing/LandingPage";
import BasicSign from "./component/Layout/BasicSign";
import StartPage from "./component/Landing/StartPage";
import InventoryList from "./component/Inventory/InventoryList";
import BasicInventory from "./component/Layout/BasicInventory";
import BasicSale from "./component/Layout/BasicSale";
import NewInventory from "./component/Inventory/NewInventory";
import SaleList from "./component/Sale/SaleList";
import NewSale from './component/Sale/NewSale'
import SaleBidList from "./component/Sale/SaleBidList";
import BasicSaleBid from "./component/Layout/BasicSaleBid";
import ProductDescribe from "./component/ProductDescribe/ProductDescribe";
import ProductList from "./component/ProductDescribe/ProductList";
const RenderRoutes = () => {
  return (
    <Routes>
      <Route path="/bnbarter" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<StartPage />} />
      <Route path="/bnbarter/login" element={<Login />} />
      <Route path="bnbarter" element={<BasicSign />} >
        <Route path="signup" element={<SignUp />} />
      </Route>
      <Route path="bnbarter" element={<Basic />} >
        <Route path="main" element={<LandingPage />} />
        <Route path="productdescribe/:postId" element={<ProductDescribe />} />
        <Route path="productlist" element={<ProductList />} />

      </Route>
      <Route path="bnbarter" element={<BasicInventory />} >
        <Route path="inventorylist" element={<InventoryList />} />
        <Route path="newinventory" element={<NewInventory />} />
      </Route>
      <Route path="bnbarter" element={<BasicSale />} >
        <Route path="salelist" element={<SaleList />} />
        <Route path="newsale" element={<NewSale />} />

      </Route>
      <Route path="bnbarter" element={<BasicSaleBid />} >
        <Route path="salebidlist" element={<SaleBidList />} />
      </Route>

      <Route path="newinventory" element={<NewInventory />} />
    </Routes >
  )
};

export default RenderRoutes;