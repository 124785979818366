
import { Switch, Cascader, Modal, Flex, message, Upload, Divider, Table, Select, Card, Pagination, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { LoadingOutlined, PlusOutlined, FileImageOutlined } from '@ant-design/icons';
import { IconMapPin, IconPlus, IconSquarePlus } from '@tabler/icons-react';
import prodCategoryDatas from '../../../src/assets/data/prodCategories.json'; // 導入 JSON 文件
import request from '../../requests/api'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ImageColumn from '../Image/ImageColumn'; // 確保路徑正確
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const NewSale = () => {
  const [inventory, setInventory] = useState([]);
  const [nameiii, setNameiii] = useState('');
  const [options, setOptions] = useState([])
  const [selectProdCond, setSelectProdCond] = useState('');
  const [prodCategories, setProdCategories] = useState([]);
  const { TextArea } = Input;
  const [subCategories, setSubCategories] = useState([]);
  const onChange = (value) => {
    console.log(value);
  };
  const language = 0
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalpVisible, setModalpVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const location = useLocation();
  const { record, mode } = location.state || {};
  useEffect(() => {
    if (mode != 'add' && mode != undefined) {
      const groups = record.groups

      const s = {};
      const q = {};
      groups.forEach(item => {
        // 轉換成 a 的格式
        q[item.skuId] = {
          skuId: item.skuId,
          qty: item.qty,
          price: item.price
        };

        // 轉換成 b 的格式
        s[item.skuId] = true;
      });
      setSelectedProducts(s)
      setQuantities(q)

      if (record) {
        const prodCond = record.prodCategories.split(",")[0]
        onProdChange(record.prodCategories.split(",")[0])
        form.setFieldsValue({
          ...record,
          prodCond: prodCond,
          face: record.deliveryInfos.find(item => item.shipTypeId === "1")?.shipExpense,
          faceSwitch: record.deliveryInfos.find(item => item.shipTypeId === "1")?.shipAvailable === "Y",
          faceLocation: record.deliveryInfos.find(item => item.shipTypeId === "1")?.location,
          sevenEleven: record.deliveryInfos.find(item => item.shipTypeId === "2")?.shipExpense,
          sevenElevenSwitch: record.deliveryInfos.find(item => item.shipTypeId === "2")?.shipAvailable === "Y",
          familyMart: record.deliveryInfos.find(item => item.shipTypeId === "3")?.shipExpense,
          familyMartSwitch: record.deliveryInfos.find(item => item.shipTypeId === "3")?.shipAvailable === "Y",
          hiLife: record.deliveryInfos.find(item => item.shipTypeId === "4")?.shipExpense,
          hiLifeSwitch: record.deliveryInfos.find(item => item.shipTypeId === "4")?.shipAvailable === "Y",
          blackCat: record.deliveryInfos.find(item => item.shipTypeId === "5")?.shipExpense,
          blackCatSwitch: record.deliveryInfos.find(item => item.shipTypeId === "5")?.shipAvailable === "Y",
          postOffice: record.deliveryInfos.find(item => item.shipTypeId === "6")?.shipExpense,
          postOfficeSwitch: record.deliveryInfos.find(item => item.shipTypeId === "6")?.shipAvailable === "Y",
        });
        form.setFieldsValue({ prodCategories: record.prodCategories.split(",").map(item => item.trim()) });

      }
    }
  }, [record]);
  function buildOptions(data, parentId = "0") {
    return data
      .filter(item => item.parentCateId === parentId)
      .map(item => ({
        value: item.id,
        label: item.name,
        children: buildOptions(data, item.id)
      }));
  }
  function findAllChildren(data, parentId) {
    let result = [];

    function findChildren(data, parentId) {
      data.forEach(item => {
        if (item.id === parentId && item.parentCateId === '0') {
          result.push(item);
        }
        if (item.parentCateId === parentId) {
          result.push(item);
          findChildren(data, item.id, ''); // 遞歸查找子項目
        }
      });
    }

    findChildren(data, parentId);

    return result;
  }

  const [form] = Form.useForm();
  const showModal = () => {
    setModalVisible(true);
  };

  const showpModal = () => {
    setModalpVisible(true);
    setSelectedItems(nowSelectedItems)
    calculateTotalPrice(nowSelectedItems);
  };

  const handleModalOk = () => {
    setModalVisible(false);

    form.setFieldsValue({ 'nameiii': nameiii })
  };
  const handlepModalOk = () => {
    setModalpVisible(false);
    console.log('select', selectedProducts, quantities)
    //setNowSelectedItems(quantities)


  };
  const handlepModalCancel = () => {
    setModalpVisible(false);
    setSelectedItems(nowSelectedItems)
    //calculateTotalPrice(nowSelectedItems);

  };
  const handleModalCancel = () => {
    setModalVisible(false);

  };

  const onCaChange = (value) => {
    // 处理级联选择器的变化
    console.log(value)
    const formattedString = value.join('/');
    setNameiii(formattedString)

  };
  const [data, setData] = useState([
    {
      key: '1', name: 'ASUS 12 智慧平版電腦 ', quantity: 1,
      price: '$9980', bid: 15, bidOther: 20, cash: 'No', date: '2024/01/05', photo: '1.png', pay: '付費行銷', w: '50', g: '50', check: false
    },

  ])
  const [nowSelectedItems, setNowSelectedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);


  const getProdCategories = async () => {
    try {
      const res = await request.get(`/bnbarter/prod-categories/${language}/0`);
      setProdCategories(res.data.datas)

    } catch (error) {
      console.log(error)
    }
  }
  const calculateTotalPrice = (items) => {
    const total = items.reduce((acc, item) => acc + parseFloat(item.price), 0);
    setTotalPrice(total);
  };
  const onProdChange = async (value) => {
    form.setFieldsValue({ prodCategories: '' });
    setSubCategories('');
    setSelectProdCond(value);
    setOptions([])
    try {
      const newProdDatas = findAllChildren(prodCategoryDatas, value)
      console.log('n', newProdDatas)
      const formattedOptions = buildOptions(newProdDatas);
      setOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching product categories:', error);
    }
  };
  useEffect(() => {
    fetchInventory()
    setSelectedItems([])
    getProdCategories()
  }, []);
  const fetchImage = async (skuId, setImagePath) => {
    try {
      const res = await request.get(`/bnbarter/mem-inventory-detail/${skuId}`);
      res.data.datas && setImagePath(res.data.datas[0].imgId);

    } catch (err) {
      console.error(err);
    }
  };



  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };


  const fetchImageData = async (imgPath, setImgData) => {
    try {
      const res = await request.get(`/bnbarter/mem-inventory-image/${imgPath}`, {
        responseType: 'arraybuffer',
      });
      const base64String = arrayBufferToBase64(res.data);
      setImgData(`data:image/jpeg;base64,${base64String}`);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchInventory = async () => {
    try {
      const response = await request.get('/bnbarter/mem-inventory');
      console.log('response.data.datas', response.data.datas)
      const memInventory = response.data.datas
      const res = await request.get('/bnbarter/mem-unposted-inventory');
      console.log('mem-unposted-inventory', res.data.datas)
      const unpostedInventory = res.data.datas
      if (mode === 'edit') {
        const groups = record.groups
        const updatedUnpostedInventory = unpostedInventory.map(item => {
          const itemG = groups.find(itemG => itemG.skuId === item.skuId);
          if (itemG) {
            return {
              ...item,
              qty: item.qty + itemG.qty
            };
          }
          return item;
        });
        const additionalMemInventory = memInventory.filter(itemMem => !unpostedInventory.some(itemG => itemG.skuId === itemMem.skuId))
          .map(itemMem => {
            const itemG = groups.find(item => item.skuId === itemMem.skuId);
            if (itemG) {
              return {
                ...itemMem,
                qty: itemG.qty
              };
            }
            return itemMem;
          });
        //setInventory(response.data.datas);
        setInventory([...additionalMemInventory, ...updatedUnpostedInventory]);

      } else {
        setInventory(response.data.datas);
      }
    } catch (err) {
      //setError(err);
    } finally {
      // setLoading(false);
    }
  };

  const onFinish = async (values) => {
    if (values.faceLocation) {
      values.faceLocation = ''
    }
    const deliveryInfos = [
      {
        shipTypeId: "1",
        shipTypeName: "面交",
        shipExpense: values.face || 0,
        shipAvailable: values.faceSwitch ? "Y" : "N",
        location: values.faceLocation
      },
      {
        shipTypeId: "2",
        shipTypeName: "7-11店到店",
        shipExpense: values.sevenEleven || 0,
        shipAvailable: values.sevenElevenSwitch ? "Y" : "N",
        location: ""
      },
      {
        shipTypeId: "3",
        shipTypeName: "全家店到店",
        shipExpense: values.familyMart || 0,
        shipAvailable: values.familyMartSwitch ? "Y" : "N",
        location: ""
      },
      {
        shipTypeId: "4",
        shipTypeName: "萊爾富店到店",
        shipExpense: values.hiLife || 0,
        shipAvailable: values.hiLifeSwitch ? "Y" : "N",
        location: ""
      },
      {
        shipTypeId: "5",
        shipTypeName: "黑貓宅配",
        shipExpense: values.blackCat || 0,
        shipAvailable: values.blackCatSwitch ? "Y" : "N",
        location: ""
      },
      {
        shipTypeId: "6",
        shipTypeName: "郵局包裹",
        shipExpense: values.postOffice || 0,
        shipAvailable: values.postOfficeSwitch ? "Y" : "N",
        location: ""
      }
    ];

    const postData = {
      deliveryInfos: deliveryInfos,
      groups: Object.values(quantities)

    };

    console.log('Post Data: ', postData, values, Object.values(quantities));
    console.log(values['prodCategories'], values['prodCategories'])
    values['prodCategories'] = values['prodCategories'].join(', ')

    if (mode === 'edit') {
      await request.patch(`/bnbarter/mem-post/${values['postId']}`, { ...values, ...postData })
        .then((res) => {
          if (res.data.code === 1) {
            Modal.success({
              content: '資料修改成功',
            });
            navigate('/bnbarter/salelist');
          } else {
            Modal.error({
              title: '資料修改失敗',
              content: res.data.msg,
            });
          }
        });
    } else {
      await request.post(`/bnbarter/mem-post`, { ...values, ...postData })
        .then((res) => {
          if (res.data.code === 1) {
            Modal.success({
              content: '資料新增成功',
            });
            navigate('/bnbarter/salelist');
          } else {
            Modal.error({
              title: '資料新增失敗',
              content: res.data.msg,
            });
          }
        });
    }

  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [selectedProducts, setSelectedProducts] = useState({});
  const [quantities, setQuantities] = useState({});

  const handleCheckboxChange = (skuId) => {
    setSelectedProducts(prev => {
      const newSelectedProducts = { ...prev, [skuId]: !prev[skuId] };
      if (!newSelectedProducts[skuId]) {
        setQuantities(prevQuantities => {
          const newQuantities = { ...prevQuantities };
          delete newQuantities[skuId];
          return newQuantities;
        });
      }
      return newSelectedProducts;
    });
  };

  const handleQuantityChange = (skuId, value) => {
    const qty = parseInt(value, 10);
    const product = inventory.find(p => p.skuId === skuId);
    setQuantities(prev => ({
      ...prev,
      [skuId]: {
        skuId,
        qty: isNaN(qty) ? 0 : qty,
        price: product.price
      }
    }));
  };


  const totalSelectItems = Object.values(quantities).reduce((acc, item) => acc + item.qty, 0);
  const totalItems = inventory.reduce((acc, product) => acc + product.qty, 0);
  const total = Object.keys(quantities).reduce((acc, skuId) => {
    const product = inventory.find(p => p.skuId === skuId);
    if (product) {
      const quantity = quantities[skuId] || 0;
      return acc + parseInt(product.price)
    }
    return acc;
  }, 0);


  return (
    <div className='bordered-div' style={{ width: '75%' }}>

      <Row >
        <Col xs={24} sm={24}>
          <span style={{ fontSize: 26, margin: 20 }}>新增上架-商品基本資料建立</span>
        </Col>
      </Row>
      <Divider />
      <Row  >
        <Col sm={2} >
        </Col>
        <Col sm={12} >
          <Form
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}

          >

            <Form.Item
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              labelAlign="left"
              label="商品圖片"
              name="productName"

            >
              <Space>
                {Object.values(quantities).map(item =>
                  <p>
                    <ImageColumn skuId={item.skuId}></ImageColumn>
                  </p>)
                }
                <Button className='addButton' onClick={() => showpModal()}  >

                  <div className='addDiv'>
                    <IconSquarePlus color='#707070' />
                    <p style={{ fontSize: '20px', margin: '0' }}>選取商品庫存</p>
                  </div>

                </Button>
              </Space>
            </Form.Item>


            <Form.Item
              label="上架名稱"
              name="postName"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input showCount maxLength={80} placeholder='請輸入上架商品組合名稱' />
            </Form.Item>

            <Space>
              <Form.Item
                label="數量"
                name="postQty"

                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label=""
                name="postQtyUnit"

                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  style={{ width: 70 }}
                  options={[
                    {
                      value: '組',
                      label: '組',
                    }
                  ]}></Select>
              </Form.Item>
            </Space>



            <Form.Item
              label="商品大分類"
              name="prodCond"


              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                onChange={onProdChange}
                style={{ width: 250 }}
                options={prodCategories.map((item) => {
                  return {
                    value: item.id,
                    label: item.name
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              label="商品小分類"
              name="prodCategories"

              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Cascader disabled={selectProdCond === ''} options={options} />
            </Form.Item>




            <Space>
              <Form.Item
                label="商品描述"
                name="prodDesc"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={2000}

                  style={{
                    width: 500,
                    height: 150,
                    resize: 'none',
                  }}
                />
              </Form.Item>



            </Space>


            <p style={{ fontSize: 26 }}>商品交付方式</p>
            <Divider />
            <Form.Item


            >
              <Row gutter={[6, 12]} >
                <Col span={2}>
                  <Form.Item
                    label="面交"
                  >
                  </Form.Item>
                </Col>
                <Col span={0}>
                  <Form.Item
                    name="face"
                    style={{ display: 'none' }}
                  >
                    <Input addonBefore="運費$" />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name="faceLocation"

                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item
                    name="faceSwitch"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={[6, 12]} >
                <Col span={10}>
                  <Form.Item
                    label="7-ELEVEN 店到店"
                  >
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="sevenEleven"

                  >
                    <Input addonBefore="運費$" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="sevenElevenSwitch"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Row gutter={6} >
                <Col span={10}>
                  <Form.Item
                    label="全家店到店"
                  >
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item

                    name="familyMart"

                  >
                    <Input addonBefore="運費$" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="familyMartSwitch"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Row gutter={6} >
                <Col span={10}>
                  <Form.Item
                    label="萊爾富店到店"
                  >
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item

                    name="hiLife"

                  >
                    <Input addonBefore="運費$" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="hiLifeSwitch"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Row gutter={6} >
                <Col span={10}>
                  <Form.Item
                    label="黑貓宅配"
                  >
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item

                    name="blackCat"

                  >

                    <Input addonBefore="運費$" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="blackCatSwitch"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>

              <Row gutter={6} >
                <Col span={10}>
                  <Form.Item
                    label="郵局包裹"
                  >
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item

                    name="postOffice"

                  >
                    <Input addonBefore="運費$" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="postOfficeSwitch"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>


            <Space >
              <Form.Item
                label="長"
                name="pkgLength"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>


              <Form.Item
                label="寬"
                name="pkgWidth"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>


              <Form.Item
                label="高"
                name="pkgHeight"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label="高"
                style={{ display: 'none' }}
                name="postId"

              >
                <Input></Input>
              </Form.Item>
            </Space >
            <Divider />

            <p style={{ fontSize: 26, margin: 20 }}> 其它說明</p>

            <Space>
              <Form.Item
                label="出貨時間(預設)"
                name="deliverDays"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <span >工作天</span>
            </Space>
            <Form.Item
              label="商品型號說明"
              name="prodModel"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="盒單狀況"
              name="prodBox"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="商品產地"
              name="mfgLocation"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Space>
              <Form.Item
                label="商品重量"
                name="pkgWeight"

                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label=""
                name="pkgWeightUnit"

                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  style={{ width: 70 }}
                  options={[
                    {
                      value: '公斤',
                      label: '公斤',
                    },
                    {
                      value: '公克',
                      label: '公克',
                    }
                  ]}></Select>
              </Form.Item>
            </Space>
            <Row >
              <Col xs={24} sm={24}>
                <Button style={{ margin: 10 }} a href='/bnbarter/salelist' >取消</Button>
                <Button style={{ margin: 10 }} htmlType="submit" type='primary' danger>儲存入庫</Button>

              </Col>
            </Row>

          </Form>
        </Col>

      </Row >

      <Modal
        title="選擇商品小分類"
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={'60%'}
      >
        <Row gutter={5}>
          <Col sm={2}>
            分類選擇:
          </Col>
          <Col sm={6}>
            <Input placeholder='快選輸入' ></Input>
          </Col>
          <Col sm={14}>
            目前選擇: {nameiii}
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Cascader.Panel options={options} style={{ marginTop: 25 }} onChange={onCaChange} />
          </Col>
        </Row>
      </Modal >
      <Modal
        title="選擇商品小分類"
        open={modalpVisible}
        onOk={handlepModalOk}
        onCancel={handlepModalCancel}
        width={'70%'}
      >

        <Row gutter={5}>
          <Col sm={3}>
            已選商品:{totalSelectItems} 件
          </Col>
          <Col sm={5}>
            目前商品共計:{totalItems} 件
          </Col>
          <Col sm={5}>
            預估總額$:{total} 元
          </Col>
        </Row>

        <Divider />
        <Row gutter={6}>
          {inventory.map(product =>
          (< Col span={6}>
            <Card  >
              <p><ImageColumn skuId={product.skuId}></ImageColumn></p>
              {product.prodName.length > 10 ? `${product.prodName.slice(0, 16)}...` : product.prodName}
              <p>金額:{product.price}</p>
              <p>數量:{product.qty}</p>
              <input
                type="checkbox"
                checked={!!selectedProducts[product.skuId]}
                onChange={() => handleCheckboxChange(product.skuId)}
              />
              <span>上架數量<Input

                value={quantities[product.skuId]?.qty || ''}
                onChange={(e) => handleQuantityChange(product.skuId, e.target.value)}
                disabled={!selectedProducts[product.skuId]}
              /> </span>

            </Card>
          </Col>
          )
          )
          }


        </Row>
      </Modal>
    </div >
  )





}
export default NewSale;
