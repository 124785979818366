
import { PlusOutlined, UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Table, Card, Pagination, Menu, theme, Popconfirm, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MapPin } from '@tabler/icons-react';
import request from '../../requests/api'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ImageColumn from '../Image/ImageColumn'; // 確保路徑正確
import { render } from '@testing-library/react';

const SaleList = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = async (postId) => {
    await request.delete(`/bnbarter/mem-post/${postId}`)
      .then((res) => {
        fetchMemPost()
      });

  };
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      width: 120,
      render: (text, record) => <ImageColumn skuId={record.groups[0].skuId} />
    },
    {
      title: '',
      dataIndex: 'name',
      width: 120,


    },
    {
      title: '物件名稱',
      dataIndex: 'postName',
      width: 200,
    },
    {
      title: '估計價格',
      dataIndex: 'price',
      align: 'right',
    },
    {
      title: '數量',
      dataIndex: 'postQty',
      align: 'right',
    },
    {
      title: '競標數',
      dataIndex: 'bid',
      align: 'right',
      render: (text, record) => text ? text : 0
    },
    {
      title: '被競標數',
      dataIndex: 'bidOther',
      align: 'right',
      render: (text, record) => text ? text : 0
    },
    {
      title: '廣告付費',
      dataIndex: 'pay',
    },
    {
      title: '瀏覽數',
      dataIndex: 'w',
      render: (text, record) => text ? text : 0
    },
    {
      title: '追蹤數',
      dataIndex: 'prodLike',
      render: (text, record) => text ? text : 0
    },
    {
      title: '上架日期',
      dataIndex: 'date',
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'action',
      render: (_, record) =>
      (
        <Space size="middle">

          <>
            <Typography.Link onClick={() => handleEdit(record)}>
              編輯資料
            </Typography.Link>


            <Popconfirm
              title="刪除"
              description="是否確認刪除?"
              onConfirm={() => confirm(record.postId)}

              okText="確定"
              cancelText="否"
            >
              <Typography.Link >刪除</Typography.Link>

            </Popconfirm>

            <Typography.Link >
              下架
            </Typography.Link>

          </>


        </Space >
      )
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },

  }

  const handleEdit = (record) => {
    navigate(`/bnbarter/newsale`, { state: { record, mode: 'edit' } });
  };


  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // 设置每页默认显示的数量
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = data.slice(startIndex, endIndex);
  const fetchMemPost = async () => {
    try {
      const response = await request.get('/bnbarter/mem-post');
      console.log(response.data)
      setData(response.data.datas);
    } catch (err) {
      //setError(err);
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    fetchMemPost();

  }, []);
  return (
    <div className='bordered-div' style={{ width: '90%' }}>

      <Row gutter={30}>
        <Col xs={24} sm={24}>
          <div style={{ margin: 10, fontSize: 18, textDecoration: 'underline' }}>

            <span style={{ margin: 10, fontSize: 18 }}>全部上架商品</span>

          </div>

        </Col>
      </Row>

      <Row gutter={30}>
        <Col xs={12} sm={12}>
          <div style={{ margin: 10, fontSize: 14 }}>
            <span>{data.length}件上架商品(尚可新增80件商品)</span>
          </div>
        </Col>
        <Col xs={12} sm={12} offset={{ xs: 0, sm: 12 }}>
          <div style={{ fontSize: 14, textAlign: 'right' }}>
            <Button style={{ margin: 5 }} type="primary" a href='newsale' danger icon={<PlusOutlined />}>新增上架</Button>
            <Button style={{ margin: 5 }} icon={<UnorderedListOutlined />} />
            <Button style={{ margin: 5 }} icon={<AppstoreOutlined />} />
          </div>
        </Col>
      </Row>
      <Row gutter={24} justify="center">
        <Col xs={24} sm={24}>
          <div style={{ display: 'flex', alignItems: 'center', }}>
            <Card style={{ width: '100%' }}>
              <Table
                rowSelection={{
                  type: Checkbox,
                  ...rowSelection,
                }}
                scroll={{ x: 'max-content' }}
                columns={columns}
                dataSource={currentData}
                pagination={false}
              />

              <Pagination style={{ margin: 10 }} showSizeChanger onShowSizeChange={handleSizeChange} defaultCurrent={1} total={data.length} onChange={handlePageChange} />
            </Card>
          </div >
        </Col>
      </Row>

    </div >
  )





}
export default SaleList
