
import { Modal, Breadcrumb, message, Select, Layout, Card, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker } from 'antd';
import request from '../../requests/auth'
import { React, useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode";
import { login, logout } from '../Login/loginSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/SignUp.css';
import { useLocation } from 'react-router-dom';
import { Network } from 'tabler-icons-react';
import moment from 'moment';
import Base64 from 'base-64';
const SignUp = () => {
  const { Title } = Typography;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState([]);
  const dispatch = useDispatch()
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0 && timer) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countdown]);



  const next = async () => {
    if (current === 3) {
      navigate('/bnbarter/main')
    }
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(0);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const name = searchParams.get('name');
    form.setFieldsValue({ 'email': email })
    form.setFieldsValue({ 'name': name })

  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
    setPhoneNumber(values['phoneNumber'])
    getSendVerifySms(values['phoneNumber'])
    next()

  };

  const onUserFinish = (values) => {
    console.log('Success:', values);
    postUser(values)
    //getSendVerifySms(values['phoneNumber'])


  };

  const onVeriftyFinish = (values) => {
    console.log('Success:', values);
    setPhoneNumber(values['phoneNumber'])
    getSmsVerifyCode(values['verifyCode'])
    //next()
  };

  const getSmsVerifyCode = async (verifyCode) => {
    try {
      const res = await request.post(`auth/sms-verify-code/${phoneNumber}`, { verifyCode: verifyCode });
      if (res.data.code === 1) {
        next()
        form.setFieldsValue({ 'cellPhone': phoneNumber })
      } else {
        Modal.error({
          title: '錯誤',
          content: '驗證碼輸入錯誤'
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  const authLogin = async (loginInfo) => {
    //navigate('/startpage');
    await request.get('/auth/token', {
      headers: {
        Authorization: `Basic ${loginInfo}`,
      },
    }).then((res) => {
      const token = res.data.token
      console.log('token', res.data)
      dispatch(login({ token: token, token2: res.data.token2 }))
    }).catch((error) => {
    })
  }
  const postUser = async (values) => {
    try {
      values['birthday'] = moment(values['birthday']).format('YYYYMMDD')
      const res = await request.post(`/auth/user`, { ...values, 'cellPhone': phoneNumber, 'account': values['email'] });
      if (res.data.code === 1) {

        const username = values['email']
        const password = values['password']

        const loginInfo = Base64.encode(`${username}:${password}`);
        authLogin(loginInfo)
        setCurrent(4)


      } else {
        Modal.error({
          title: '錯誤',
          content: '帳號建立錯誤'
        });
      }

    } catch (error) {
      Modal.error({
        title: '錯誤',
        content: '帳號建立失敗'
      });
    }
  }



  const getSendVerifySms = async (phoneNumber) => {
    try {
      const res = await request.post(`auth/send-verify-sms/${phoneNumber}`);
      if (res.data.code === 1) {
        next()
      }

    } catch (error) {
      console.log(error)
    }
  }


  const validatePhone = async (rule, value) => {
    const reg = /^09[0-9]{8}$/;
    if (!value) {
      return Promise.resolve();
    }
    if (value && !reg.test(value)) {
      return Promise.reject('請輸入有效的手機號碼！');
    } else {
      try {
        const response = await request.get(`/auth/is-valid-cell-phone/${value}`);
        if (response.data.data === 'N') {
          return Promise.resolve();
        } else {
          return Promise.reject(`${value}已註冊過`);
        }
      } catch (error) {
        return Promise.reject('驗證手機號碼時出錯');
      }
    }
  };





  const sendEmailForm = async () => {
    setCountdown(60); // 開始60秒倒計時
    try {
      await request.post(`/auth/send-verify-email/${form.getFieldValue('email')}`,);
      message.success('認證郵件已發送');
    } catch (error) {
      message.error('發送認證郵件失敗');
    }
  }

  const handleIdNumberChange = (e) => {
    const { value } = e.target;
    // 將輸入的字母轉換為大寫
    const uppercasedValue = value.replace(/[a-z]/g, (char) => char.toUpperCase());
    form.setFieldsValue({ 'idNumber': uppercasedValue });
  };
  const isValidatePhone = async (value, callback) => {
    try {
      const response = await request.get(`/auth/is-valid-cell-phone/${value}`);
      if (response.data === 'Y') {
        return callback();
      } else {
        return callback('此行動電話號碼已註冊過');
      }
    } catch (error) {
      return callback('驗證行動電話號碼時出錯');
    }
  };

  const isValidateUser = async (rule, value, callback) => {
    if (!value) {
      return Promise.resolve();
    }
    try {
      const response = await request.get(`/auth/is-valid-user/${value}`);
      if (response.data.data === 'N') {
        return Promise.resolve();
      } else {
        return Promise.reject(`${value}已註冊過`);
      }
    } catch (error) {
      return Promise.reject('驗證E-mail時出錯');
    }
  };


  return (
    <div >
      < Row justify="center" >
        <Col xs={20} sm={11} >
          <Steps
            labelPlacement="vertical"
            current={current}
            items={[
              {
                title: '輸入手機號碼',
              },
              {
                title: '手機身分驗證',

              },
              {
                title: '個資登入',


              },
              {
                title: '個人喜好',

              },
              ,
              {
                title: '完成註冊',

              },
            ]}
          />
        </Col>
      </Row >
      {current === 0 && (<><Row justify="center" gutter={24}>
        <div align='middle' style={{ marginTop: 110, marginBottom: 70 }}>
        </div>
      </Row>
        <Row justify="center" gutter={90}>
          <Col sm={12} >
            <Form onFinish={onFinish}>
              <Form.Item name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: '請輸入行動電話號碼',
                  },
                  { validator: validatePhone },
                ]}
              >
                <Input className='phone-number-input' placeholder='您的行動電話號碼' />
              </Form.Item>
              <Form.Item>
                <Button className='next-button' htmlType="submit">
                  下一步
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>)
      }
      {
        current === 1 && (
          <>
            <Row justify="center" align="middle" gutter={24}>
              <Col align='middle' style={{ marginTop: 110, marginBottom: 70 }}>
                <p className='code-text'>輸入驗證碼</p>
                <p className='phone-message-text'>簡訊已送到: {phoneNumber}</p>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Col sm={10} align='middle'>
                <Form onFinish={onVeriftyFinish}>
                  <Form.Item name="verifyCode"
                    rules={[
                      {
                        required: true,
                        message: '請輸入驗證碼',
                      },
                    ]}
                  >
                    <Input.OTP className='phone-input' length={6} />
                  </Form.Item>
                  <Form.Item>
                    <Button className='next-button' htmlType="submit">
                      下一步
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )
      }
      {
        current === 2 && (<>
          <div align='middle' style={{ marginTop: 50, marginBottom: 20 }}>
            <Col  >
              <p className='code-text'>個人資料登入</p>

            </Col>
          </div>
          <Row justify="center" >
            <Col sm={8} >
              <Form form={form}
                layout='vertical'
                autoComplete="off"
                onFinish={onUserFinish}
              >
                <Form.Item
                  label="真實姓名"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: '真實姓名',
                    },
                  ]}
                >
                  <Input style={{ borderBottom: '1px solid #000', borderRadius: '0' }} variant={false} />
                </Form.Item>
                <Form.Item
                  label="出生/年/月/日"
                  name="birthday"
                  rules={[
                    {
                      required: true,
                      message: '請輸入出生/年/月/日',
                    },
                  ]}
                >
                  <DatePicker format='YYYY/MM/DD'></DatePicker>
                </Form.Item>

                <Form.Item
                  label="性別"
                  name="sex"

                  rules={[
                    {
                      required: true,
                      message: '請輸入性別',
                    },
                  ]}
                >
                  <Select
                    style={{ width: 70 }}
                    options={[
                      {
                        value: '男',
                        label: '男',
                      },
                      {
                        value: '女',
                        label: '女',
                      }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="身分證字號"
                  name="idNumber"
                  rules={[
                    {
                      pattern: /^[A-Z]{1}[1-2]{1}[0-9]{8}$/,
                      message: '身份證字號格式不正確',
                    },
                  ]}
                >
                  <Input style={{ borderBottom: '1px solid #000', borderRadius: '0' }} onChange={handleIdNumberChange} variant={false} />
                </Form.Item>
                <Form.Item label="電子郵件">
                  <Space>
                    <Form.Item

                      name="email"
                      rules={[
                        {
                          required: true,
                          message: '請輸入電子郵件',
                        },
                        {
                          type: 'email',
                          message: '請輸入有效的電子郵件',
                        }, {
                          validator: isValidateUser
                        }
                      ]}
                    >
                      <Input style={{ borderBottom: '1px solid #000', borderRadius: '0' }} variant={false} />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className='mail-button'
                        disabled={countdown > 0}
                        onClick={sendEmailForm}
                      >
                        {countdown > 0 ? `重新按下驗證 (${countdown}s)` : '驗證'}
                      </Button>
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item
                  label="地址"
                  name="address"

                >




                  <Input style={{ borderBottom: '1px solid #000', borderRadius: '0' }} variant={false} />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="密碼"
                  rules={[
                    {
                      required: true,
                      message: '請輸入密碼',
                    },
                  ]}

                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="確認密碼"
                  dependencies={['password']}

                  rules={[
                    {
                      required: true,
                      message: '請確認密碼是否正確',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('密碼跟第一次輸入不相同'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button className='next-button' htmlType="submit">
                    下一步
                  </Button>
                </Form.Item>
              </Form>
            </Col>

          </Row ></>)
      }
      {
        current === 3 && (
          <div align='middle'>
            <Row justify="center" gutter={24}>

              <Col  >
                <p className='code-text'> 個人化AI模型</p>
              </Col>

            </Row>
            <Row justify="center" gutter={24}>

              <Tooltip title="Useful information">
                <p className='phone-message-text'>請按照喜歡的順序依次勾選您喜歡的圖片</p>

              </Tooltip>
            </Row>
            <Row justify="center" gutter={24}>
              <Col span={4}>
                <Card >
                  <Checkbox className="checkbox" />
                  <img
                    style={{ padding: 5 }}
                    alt="example"
                    src="https://s.lativ.com.tw/i/60421/60421021/6042102_500.jpg"
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card >
                  <Checkbox className="checkbox" />
                  <img
                    style={{ padding: 5 }}
                    alt="example"
                    src="https://s.lativ.com.tw/i/60436/60436011/6043601_500.jpg"
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card >
                  <Checkbox className="checkbox" />
                  <img
                    style={{ padding: 5 }}
                    alt="example"
                    src="https://s.lativ.com.tw/i/60436/60436011/6043601_500.jpg"
                  />
                </Card>
              </Col><Col span={4}>
                <Card >
                  <Checkbox className="checkbox" />
                  <img
                    style={{ padding: 5 }}
                    alt="example"
                    src="https://s.lativ.com.tw/i/58945/58945011/5894501_500.jpg"
                  />
                </Card>
              </Col>
            </Row>

          </div>)
      }
      {
        current === 4 && (
          <div align='middle'>
            <Row justify="center" gutter={24}>
              <Col sm={8} >
                <p className='code-text'></p>
                <p className='code-text'>恭喜你!註冊完成</p>

                <div >
                  <img src="/images/signup/finish.png" style={{ height: '180px', borderRadius: '180px' }} />
                </div>
                <p style={{ fontSize: 25, margin: '50px' }}> 一級會員</p>

                <p className='phone-message-text'>
                  現在您可以使用電子郵件登入囉 !
                </p>
                <span className='phone-message-text'>*把握開台優惠大放送，只要符合優惠規定，將可以</span> <span style={{ color: 'rgba(0, 0, 0, 0.7)', fontWeight: 'bold', fontSize: 25 }}>連跳三級</span>

                <p className='phone-message-text'>(詳細請參考優惠大放送規定)</p>

                <p style={{ fontSize: 25, margin: '50px' }}>    <a href='/bnbarter/main'>【開始瀏覽商品】</a>
                </p>
              </Col>
            </Row>
          </div>)
      }

    </div >
  )

}
export default SignUp
