// ImageColumn.js
import React, { useState, useEffect } from 'react';
import request from '../../requests/api'
const ImageColumn = ({ skuId }) => {
    const [imgPath, setImagePath] = useState('');
    const [imgData, setImgData] = useState(null);

    useEffect(() => {
        fetchImage(skuId, setImagePath);
    }, [skuId]);

    useEffect(() => {
        if (imgPath) {
            fetchImageData(imgPath, setImgData);
        }
    }, [imgPath]);

    return imgData ? (
        <img
            src={imgData}
            alt="Inventory"
            style={{ maxHeight: '80px', maxWidth: '80px', objectFit: 'contain' }}

        />
    ) : null;
};

const fetchImage = async (skuId, setImagePath) => {
    try {
        const res = await request.get(`/bnbarter/mem-inventory-detail/${skuId}`);
        if (res.data.datas && res.data.datas[0]) {
            setImagePath(res.data.datas[0].imgId);
        }
    } catch (err) {
        console.error(err);
    }
};

const fetchImageData = async (imgPath, setImgData) => {
    try {
        const res = await request.get(`/bnbarter/mem-inventory-image/${imgPath}`, {
            responseType: 'arraybuffer',
        });
        const base64String = arrayBufferToBase64(res.data);
        setImgData(`data:image/jpeg;base64,${base64String}`);
    } catch (err) {
        console.error(err);
    }
};

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export default ImageColumn;
