import axios from 'axios';
import { useSelector } from 'react-redux'


const instance = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_API_URL}`,
});

// http request interceptors

instance.interceptors.request.use(config => {
  //config.headers.Authorization = `Basic ${token})`;
  /*const token = stores.getState().user.token;
if (token) {
   config.headers.Authorization = `Basic ${ token } `;
 }*/

  return config;
});

// http response interceptors

export default instance;
