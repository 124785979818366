
import { Spin, Modal, Avatar, Table, Divider, Breadcrumb, Select, Layout, Card, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { StarOutlined, MenuUnfoldOutlined, RightOutlined } from '@ant-design/icons';
import { FaRegThumbsUp, FaRegHeart } from "react-icons/fa";
import { LuShoppingCart } from "react-icons/lu";
import { FaCartShopping, FaStar } from "react-icons/fa6";
import { useParams } from 'react-router-dom';
import request from '../../requests/api'
import ImageFile from '../Image/ImageFile'
import { getImageBinaryData } from '../Image/ImageFileBlob';
import { PlusOutlined, UnorderedListOutlined, RobotOutlined } from '@ant-design/icons';
import { AppstoreOutlined, BarsOutlined, HeartOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const ProductList = () => {
  const [selectedButton, setSelectedButton] = useState('grid');
  const { Text } = Typography;
  const { Meta } = Card;
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [price, setPrice] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageFile, setImageFile] = useState(null)
  const [productName, setProductName] = useState(null)
  const navigate = useNavigate();

  const fetchMemPost = async () => {
    try {
      let url = '/bnbarter/mem-post-guest'
      const response = await request.get('/bnbarter/mem-post-guest');
      setDatas(response.data.datas);
    } catch (err) {
      navigate('/login')
    } finally {
      //navigate('/login')
    }
  };
  useEffect(() => {
    fetchMemPost();

  }, []);
  const handleSettingClick = async (id, postName) => {
    setLoading(true);
    setIsModalVisible(true)
    setResult('')
    const formData = new FormData();

    const binaryData = await getImageBinaryData(id, postName);
    if (binaryData) {
      const blob = new Blob([binaryData], { type: 'image/jpeg' });
      formData.append('file', blob, 'image.jpg');
      formData.append('productName', postName);
    } else {
      console.error('Failed to retrieve image data');
      setLoading(false);
      //setIsModalVisible(false)
      return;
    }
    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };


    try {
      const response = await fetch('https://rocker-ai.com:5000/upload', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('API response:', result);
      const resultPrice = result.split('\n')
      setResult(result.replace(resultPrice[0], ''));
      setPrice(resultPrice[0])
      setIsModalVisible(true)
      setProductName(postName)
      setImageFile(<ImageFile imgId={id} imageWidth={'200px'} />)
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);

    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const fetchImages = (imagePaths) => {
    return imagePaths.map((path) => {
      return {
        original: `${process.env.REACT_APP_API_URL}/bnbarter/mem-inventory-image/${path}`,
        thumbnail: `${process.env.REACT_APP_API_URL}/bnbarter/mem-inventory-image/${path}`,
      };
    });


  };






  const contentStyle = {
    margin: 0,
    height: '350px',
    textAlign: 'center',
  };


  // 計算顯示文字的函數
  function calculateDisplayText(text, maxLength) {
    const length = Array.from(text).reduce((acc, char) => {
      return acc + (char.charCodeAt(0) > 255 ? 2 : 1);
    }, 0);

    if (length <= maxLength) {
      return text;
    } else {
      let displayLength = 0;
      let displayText = '';
      for (let i = 0; i < text.length; i++) {
        displayLength += text.charCodeAt(i) > 255 ? 2 : 1;
        if (displayLength > maxLength) {
          return displayText + '...';
        }
        displayText += text[i];
      }
      return displayText;
    }
  }

  const handleClick = (viewType) => {

    setSelectedButton(viewType);
  };


  const columns = [
    {
      title: '',
      dataIndex: 'images',
      key: 'images',
      render: (text) => (
        <ImageGallery autoPlay={true} showNav={false} items={fetchImages(text)} showThumbnails={false} showBullets={true} showPlayButton={false} showFullscreenButton={false} />
      ),
      width: '15%'

    },
    {
      title: '',
      dataIndex: 'images',
      key: 'images',
      render: (text, record) => (
        <>
          <div style={{
            position: 'absolute',
            top: '0',
            marginTop: 20
          }}>
            <a href={`/bnbarter/productdescribe/${record.postId}`}>
              <span style={{ fontSize: '18px', color: '#191c1f', fontWeight: 'bold' }}>{record.postName}</span>
              <div style={{ height: '40px', marginBottom: '10px', fontSize: '13px', color: '#3d4245' }} >
                <span>
                  {calculateDisplayText(record.prodDesc, 200)}
                </span>
              </div>
            </a>
          </div>
          <div style={{
            position: 'absolute',
            bottom: '0',
            marginBottom: 15
          }}>
            <p style={{ color: '#fa8232', fontSize: '14px', fontWeight: 'bold' }}>
              <HeartOutlined></HeartOutlined> ({record.prodLike})
            </p>
            <p style={{ color: '#2da5f3', fontSize: '14px', fontWeight: 'bold' }}>
              Bid: {Math.floor(Math.random() * 100) + 1}人
            </p>
          </div>
        </>
      ),
      width: '60%'

    },
    {
      title: '',
      dataIndex: 'prodLike',
      key: 'prodLike',
      width: '10%',
      render: (text, record) => (
        <div style={{
          position: 'absolute',
          bottom: '0',
          marginBottom: 15
        }}>

          <Button
            icon={<HeartOutlined></HeartOutlined>}
            size='large'


          >
            收藏
          </Button>


        </div>
      )
    },
    {
      title: '',
      dataIndex: 'prodLike',
      key: 'prodLike',
      width: '10%',
      render: (text, record) => (
        <div style={{
          position: 'absolute',
          bottom: '0',
          marginBottom: 15
        }}>

          <Button
            icon={<FaCartShopping />}
            size='large'
            style={{

              width: '100%',
              borderColor: '#fa8232 ',
              backgroundColor: '#fa8232',
              color: 'white', // Optional: change text color when hovered
            }}

          >
            BID
          </Button>

        </div>
      )
    },
  ];
  const images2 = [
    {
      original: `/images/pandingpage/9.jpg`,
      thumbnail: `/images/pandingpage/9.jpg`,
    },
    {
      original: `/images/pandingpage/9.jpg`,
      thumbnail: `/images/pandingpage/9.jpg`,
    }
  ];


  return (
    <div >
      <Row justify="center">
        <Col xs={0} sm={22}>
          <Row >
            {/* 左边的大区域 */}
            <Col span={16}>
              <div style={{ backgroundColor: '#52c41a', textAlign: 'center', height: '310px', margin: '5px' }}>
                <img src="/images/pandingpage/1.webp" alt="1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </div>
            </Col>
            {/* 右边的小区域 */}
            <Col span={8}>
              <Row >
                <Col span={24}>
                  <div style={{ backgroundColor: '#1890ff', textAlign: 'center', height: '150px', margin: '5px' }}>
                    <img src="/images/pandingpage/two.png" alt="2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </div>
                </Col>
                <Col span={24}>
                  <div style={{ backgroundColor: '#faad14', textAlign: 'center', height: '150px', margin: '5px' }}>
                    <img src="/images/pandingpage/three.png" alt="3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row >
      <Row justify="center" style={{ marginTop: 20 }} gutter={24}>
        <Col xs={4} sm={4} >
          <span style={{ fontSize: 20 }}>商品過濾</span>
          <Divider />
          <MenuUnfoldOutlined></MenuUnfoldOutlined>地區

          <p><RightOutlined></RightOutlined>不拘</p>
          <p style={{ marginLeft: 15 }}>台北</p>
          <p style={{ marginLeft: 15 }}>桃園</p>
          <p style={{ marginLeft: 15 }}>新竹</p>
          <p style={{ marginLeft: 15 }}>更多</p>
          <Divider />
          <MenuUnfoldOutlined></MenuUnfoldOutlined>商品狀態

          <p><RightOutlined></RightOutlined>不拘</p>
          <p style={{ marginLeft: 15 }}>全新</p>
          <p style={{ marginLeft: 15 }}>二手</p>
          <Divider />
          <MenuUnfoldOutlined></MenuUnfoldOutlined>運送方式

          <p><RightOutlined></RightOutlined>不拘</p>
          <p style={{ marginLeft: 15 }}>7-ELEVEN店到店</p>
          <p style={{ marginLeft: 15 }}>全家店到店</p>
          <p style={{ marginLeft: 15 }}>萊爾富店到店</p>
          <p style={{ marginLeft: 15 }}>郵局寄送</p>
          <p style={{ marginLeft: 15 }}>黑貓宅配</p>
          <Divider />
          <MenuUnfoldOutlined></MenuUnfoldOutlined>賣家評價

          <p><RightOutlined></RightOutlined>不拘</p>
          <p style={{ marginLeft: 15 }}>有交易紀錄</p>
          <p style={{ marginLeft: 15 }}>無負評</p>
          <p style={{ marginLeft: 15 }}>低風顯指數</p>

          <Divider />
        </Col>
        <Col xs={18} sm={18}>
          <span style={{ fontSize: 20 }}>商品分類A</span>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button type="text"
                icon={<AppstoreOutlined style={{ color: selectedButton === 'grid' ? '#e25e4c' : undefined }} />}
                onClick={() => handleClick('grid')}
              />
              <Button
                type="text"
                icon={<BarsOutlined style={{ color: selectedButton === 'list' ? '#e25e4c' : undefined }} />}
                onClick={() => handleClick('list')}
              />
            </Space>
          </div>
          <Divider />
          <Row gutter={10} >

            {selectedButton == 'grid' && datas.map(item =>
              <Col span={4}>
                <Card
                  hoverable
                  style={{
                    marginBottom: 30,
                    position: 'relative',
                  }}
                  onClick={() => window.location.href = `/bnbarter/productdescribe/${item.postId}`} // Add this line
                  cover={<ImageFile imgId={item.images[0]} />}
                  actions={[

                  ]}
                >

                  <Meta
                    title={<span style={{ fontSize: '18px', color: '#5e6282' }}>{item.postName}</span>}
                    description={
                      <div style={{ height: '40px', marginBottom: '10px', fontSize: '13px' }} >
                        <span>
                          {calculateDisplayText(item.prodDesc, 34)}
                        </span>
                      </div>
                    }
                  />

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <span style={{ color: '#2da5f3', fontSize: '14px', fontWeight: 'bold' }}>
                      Bid: {Math.floor(Math.random() * 100) + 1}人
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', whiteSpace: 'nowrap' }}>
                      <RobotOutlined
                        key="setting"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSettingClick(item.images[0], item.postName);
                        }}
                      />
                    </div>
                  </div>

                </Card>

              </Col>
            )}
          </Row>
          {selectedButton != 'grid' &&
            <Row gutter={10} >
              <Col span={24}>
                <Table dataSource={datas} showHeader={false} columns={columns} />;
              </Col>
            </Row>
          }
        </Col>
      </Row >

      <Modal
        title=""
        footer={
          null
        }
        onCancel={handleOk}
        open={isModalVisible}

      >
        {loading && (
          <>
            <div>AI分析估價中，請稍後...</div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </>
        )}
        {result && (
          <>
            {imageFile}
            <Text style={{ fontSize: 14, whiteSpace: 'pre-line', }}>商品名稱:</Text>
            <Text style={{ fontSize: 14, whiteSpace: 'pre-line', }}>{productName}</Text>
            <Text style={{ fontSize: 14, whiteSpace: 'pre-line', }}><br />評估金額:</Text>
            < Text style={{ fontSize: 14, color: 'red', whiteSpace: 'pre-line' }}>{price.replace('[', '').replace(']', '')}</Text>
            < Text style={{ fontSize: 14, whiteSpace: 'pre-line' }}>{result.replace('>', '').replace('<', '').replace('[', '').replace(']', '').replace('[', '').replace(']', '')}</Text>
          </>)}
      </Modal>
    </div >)





}
export default ProductList
