
import { PlusOutlined, UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Table, Modal, Card, Pagination, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import request from '../../requests/api'
import { useNavigate } from 'react-router-dom';
import ImageColumn from '../Image/ImageColumn'; // 確保路徑正確
const InventoryList = () => {
  const navigate = useNavigate();

  const [inventory, setInventory] = useState([]);
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      width: 120,
      render: (text, record) => <ImageColumn skuId={record.skuId} />
    },
    {
      title: '物件名稱',
      dataIndex: 'prodName',
    },
    {
      title: '金額',
      dataIndex: 'price',
      align: 'right',
    },
    {
      title: '數量',
      dataIndex: 'qty',
      align: 'right',
    },
    {
      title: '競標數',
      dataIndex: 'bid',
      align: 'right',
      render: (text, record) => text ? text : 0
    },
    {
      title: '被競標數',
      dataIndex: 'bidOther',
      align: 'right',
      render: (text, record) => text ? text : 0
    },
    {
      title: '入庫日期',
      dataIndex: 'updateTmsp',
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'action',

      render: (_, record) =>
      (
        <Space size="middle">

          <>
            <Typography.Link onClick={() => handleEdit(record)}>
              編輯資料
            </Typography.Link>
            <Typography.Link >
              刪除
            </Typography.Link>
            <Typography.Link >
              單品上架
            </Typography.Link>

          </>


        </Space >
      )
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },

  }

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // 设置每页默认显示的数量
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const fetchInventory = async () => {
    try {
      const response = await request.get('/bnbarter/mem-inventory');
      console.log(response.data)
      setInventory(response.data.datas);
    } catch (err) {
      navigate('/login')
    } finally {
      // navigate('/login')
      // setLoading(false);
    }
  };
  const fetchImage = async (skuId, setImagePath) => {
    try {
      const res = await request.get(`/bnbarter/mem-inventory-image/${skuId}`);
      res.data.datas && setImagePath(res.data.datas[0].imgId);

    } catch (err) {
      console.error(err);
    }
  };
  const handleEdit = (record) => {
    navigate(`/bnbarter/newinventory`, { state: { record, mode: 'edit' } });
  };

  const handleAdd = () => {
    navigate(`/bnbarter/newinventory`, { state: { mode: 'new' } });
  };


  const onSaveMemInventory = async (values) => {
    console.log(values)
    await request.get(`/bnbarter/mem-inventory`, {
      ...values
    }).then((res) => {
      if (res.data.code === 1) {
        Modal.success({
          content: '資料新增成功',
        });
      } else {
        Modal.error({
          title: '資料新增失敗',
          content: res.data.msg,
        });
      }
    });

  }

  useEffect(() => {
    fetchInventory();

  }, []);
  return (
    <div className='bordered-div' style={{ width: '90%' }}>

      <Row gutter={30}>
        <Col xs={24} sm={24}>
          <div >
            <Button style={{ margin: 5 }}  ><span style={{ fontSize: 18 }}>全部庫存</span></Button>
            <Button style={{ margin: 5 }} ><span style={{ fontSize: 18 }}>上架中</span></Button>
            <Button style={{ margin: 5 }} ><span style={{ fontSize: 18 }}>競標中</span></Button>
            <Button style={{ margin: 5 }} ><span style={{ fontSize: 18 }}>已結標</span></Button>
            <Button style={{ margin: 5 }} ><span style={{ fontSize: 18 }}>可用庫存</span></Button>

          </div>

        </Col>
      </Row>

      <Row gutter={30}>
        <Col xs={12} sm={12}>
          <div style={{ margin: 10, fontSize: 14 }}>
            <span>{inventory.length}件庫存品</span>
          </div>
        </Col>
        <Col xs={12} sm={12} offset={{ xs: 0, sm: 12 }}>
          <div style={{ fontSize: 14, textAlign: 'right' }}>
            <Button style={{ margin: 5 }} type="primary" a href='newinventory' danger icon={<PlusOutlined />}>新增庫存</Button>

          </div>
        </Col>
      </Row>
      <Row gutter={24} justify="center">
        <Col xs={24} sm={24}>
          <div style={{ display: 'flex', alignItems: 'center', }}>
            <Card style={{ width: '100%' }}>
              <Table
                rowSelection={{
                  type: Checkbox,
                  ...rowSelection,
                }}
                scroll={{ x: 'max-content' }}
                columns={columns}
                dataSource={inventory}
                pagination={false}
              />
              <Button style={{ margin: 5 }} type="primary" danger >合併上架</Button>
              <Pagination style={{ margin: 10 }} showSizeChanger onShowSizeChange={handleSizeChange} defaultCurrent={1} total={inventory.length} onChange={handlePageChange} />
            </Card>
          </div >
        </Col>
      </Row>

    </div >
  )





}
export default InventoryList
