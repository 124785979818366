
import { Modal, Avatar, Table, Divider, Breadcrumb, Select, Layout, Card, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { StarOutlined } from '@ant-design/icons';
import { FaRegThumbsUp, FaRegHeart } from "react-icons/fa";
import { LuShoppingCart } from "react-icons/lu";
import { FaCartShopping, FaStar } from "react-icons/fa6";
import { redirect, useParams } from 'react-router-dom';
import request from '../../requests/api'
import { PlusOutlined, UnorderedListOutlined, AppstoreOutlined, RobotOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const fetchImageData = async (imgPath) => {
  try {
    const res = await request.get(`/bnbarter/mem-inventory-image/${imgPath}`, {
      responseType: 'arraybuffer',
    });
    const base64String = arrayBufferToBase64(res.data);
    return `data:image/jpeg;base64,${base64String}`;
  } catch (err) {
    console.error(err);
  }
};

const fetchImages = async (imagePaths) => {
  const imagePromises = imagePaths.map(async (path) => {
    const imageData = await fetchImageData(path);
    return {
      original: imageData,
      thumbnail: imageData, // 如果縮略圖和原始圖像相同
    };
  });

  return Promise.all(imagePromises);
};
const ProductDescribe = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const { Meta } = Card;
  const [current, setCurrent] = useState(0);
  const [people, setPeople] = useState(2)
  const oldPeople = 2
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(0);
  };
  const contentStyle = {
    margin: 0,
    height: '350px',


    textAlign: 'center',
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  const [datas, setDatas] = useState([]);
  const [data, setData] = useState([]);
  const [bilStatus, setBilStatus] = useState('');
  const [price, setPrice] = useState(0);
  const { postId } = useParams();
  const fetchMemPost = async () => {
    try {
      let url = '/bnbarter/mem-post-guest'
      if (token) {
        url = '/bnbarter/mem-post?isAll=Y'
      }
      const response = await request.get(url);
      console.log(response.data)
      setDatas(response.data.datas);
      setData(response.data.datas.find(item => item.postId === postId))
    } catch (err) {
      //setError(err);
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    fetchMemPost();

  }, []);





  const [thumbsUpNumber, setThumbsUpNumber] = useState(0);
  const [loveNumber, setLoveNumber] = useState(data ? data.prodLike : 0);
  const [hovered, setHovered] = useState(data ? data.isActiveProdLike === 'Y' : false);
  const [bided, setBided] = useState(false);
  const [thumbsUp, setThumbsUp] = useState(false);
  const handleMouseEnter = (postId) => {
    if (!token) {
      navigate('/login');
    } else {
      if (data.isActiveProdLike === 'Y') {
        setData({ ...data, isActiveProdLike: 'N', prodLike: parseInt(data.prodLike) - 1 })
        request.delete(`/bnbarter/prod-like/${postId}`)

      } else {
        request.patch(`/bnbarter/prod-like/${postId}`)
        setData({ ...data, isActiveProdLike: 'Y', prodLike: parseInt(data.prodLike) + 1 })
      }
    }
  };

  const handleBidEnter = () => {
    if (bided) {
      //setBided(false);
      //setBilStatus('')
      setModalpVisible(true)
    } else {
      //setBided(true);
      setModalpVisible(true)

    }
  };


  const images2 = [
    {
      original: `/images/pandingpage/9.jpg`,
      thumbnail: `/images/pandingpage/9.jpg`,
    },
    {
      original: `/images/pandingpage/9.jpg`,
      thumbnail: `/images/pandingpage/9.jpg`,
    }
  ];




  const [images, setImages] = useState([]);

  useEffect(() => {
    if (datas.length > 0) {
      if (data) {
        console.log('ppp', postId)
        const imagePaths = data.images;
        console.log('iii', data.images)
        fetchImages(imagePaths).then(setImages);
        const totalPrice = data.groups.reduce((acc, item) => acc + item.price, 0);
        setPrice(totalPrice)
      }
    }
  }, [datas]);


  const handleThumbsUpEnter = (postId) => {

    //request.patch(`/bnbarter/prod-like/${postId}`)
    if (thumbsUp) {
      setThumbsUp(false);
      setThumbsUpNumber(thumbsUpNumber - 1)
    } else {
      setThumbsUp(true);
      setThumbsUpNumber(thumbsUpNumber + 1)
    }
  };


  const columns = [
    {
      title: '物件名稱',
      dataIndex: 'name',
      render: (text, record) => (
        <><p><Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${record.key}`}></Avatar>{text}</p>
          <span style={{ marginLeft: 35, color: '#FF7F27' }} ><StarOutlined /><StarOutlined /><StarOutlined /><StarOutlined /><StarOutlined /></span>
          <p style={{ marginLeft: 35 }} > {record.q}</p>
          <p style={{ marginLeft: 35 }} > 2023/12/23 14:55</p>
          <p style={{ marginLeft: 80 }}>賣家回覆:{record.ans}</p>
        </>
      )
    }
  ];

  const qData = [{ key: '1', name: 'Will', q: '請問商品全新嗎?', ans: '請看說明' },
  { key: '2', name: 'Emma', q: '請問是否可以快點結標?', ans: '沒問題，會盡快決定' },
  { key: '3', name: 'Liam', q: '請問保固還在嗎?', ans: '不好意思，不在囉' },
  { key: '4', name: 'Olivia', q: '請問是否可以免運?', ans: '可以哦' },
  { key: '5', name: 'Noah', q: '我可以用xxx 跟你換這個嗎?', ans: '我考慮一下' },]

  const [modalVisible, setModalVisible] = useState(false);
  const [modalpVisible, setModalpVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);


  const [form] = Form.useForm();
  const showModal = () => {
    setModalVisible(true);
  };

  const showpModal = () => {
    setModalpVisible(true);
    setSelectedItems(nowSelectedItems)
    calculateTotalPrice(nowSelectedItems);
  };

  const handleModalOk = () => {
    setModalVisible(false);
  };
  const handlepModalOk = () => {
    setModalpVisible(false);
    setNowSelectedItems(selectedItems)
    if (selectedItems.length === 0) {
      setBided(false);
      setBilStatus('')
      setPeople(oldPeople)
    } else {
      setBided(true);
      setBilStatus('(已下標)')
      setPeople(oldPeople + 1)
    }

  };
  const handlepModalCancel = () => {
    setModalpVisible(false);
    if (bided) {
      if (selectedItems.length === 0) {
        setBided(false);
        setBilStatus('')
        setPeople(oldPeople)
      } else {
        setBilStatus('(已下標)')
        setPeople(oldPeople + 1)
      }
    }
  };
  const handleModalCancel = () => {
    setModalVisible(false);


  };
  const [nowSelectedItems, setNowSelectedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const handleCheckboxChange = (key) => {
    const updatedSelectedItems = [...selectedItems];
    const selectedItem = bdata.find(item => item.key === key);

    if (selectedItem) {
      selectedItem.check = !selectedItem.check;

      if (selectedItem.check) {
        updatedSelectedItems.push(selectedItem);
      } else {
        const index = updatedSelectedItems.findIndex(item => item.key === key);
        if (index !== -1) {
          updatedSelectedItems.splice(index, 1);
        }
      }

      setSelectedItems(updatedSelectedItems);
      calculateTotalPrice(updatedSelectedItems);
    }
  };

  const calculateTotalPrice = (items) => {
    const total = items.reduce((acc, item) => acc + parseFloat(item.price.replace('$', '')), 0);
    setTotalPrice(total);
  };
  const [bdata, setBData] = useState([
    {
      key: '1', name: 'ASUS 12 智慧平版電腦 ', quantity: 1,
      price: '$9980', bid: 15, bidOther: 20, cash: 'No', date: '2024/01/05', photo: '1.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '2', name: '現金物件', quantity: 2,
      price: '$1000', bid: 20, bidOther: 30, cash: 'No', date: '2024/01/06',
      photo: '2.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '3', name: '滑鼠', quantity: 1, price: '$399',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '3.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '4', name: '星巴克禮卷', quantity: 1, price: '$500',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '4.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '5', name: '籃球', quantity: 1, price: '$200',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '5.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '6', name: '手鍊', quantity: 1, price: '$999',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '6.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '7', name: '玩具', quantity: 1, price: '$500',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '7.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '8', name: '盆栽', quantity: 1, price: '$200',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '8.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '9', name: 'usb充電線', quantity: 1, price: '$100',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '9.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '10', name: '腳踏車', quantity: 1, price: '$1500',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '10.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
    {
      key: '11', name: '抹布', quantity: 1, price: '$100',
      bid: 5, bidOther: 6, cash: 'Yes', date: '2024/01/07', photo: '11.png', pay: '付費行銷', w: '50', g: '50', check: false
    },
  ])


  return (
    <div className='bordered-div' style={{ width: '90%' }}>
      <Row justify="center">
        <Col xs={22} sm={22}>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: '商品分類1',
              },
              {
                title: '商品分類2',
              },
              {
                title: '商品分類3',
              },
              {
                title: 'An Application',
              },
            ]}
          />
        </Col>
      </Row >
      <Row justify="center" style={{ margin: 20 }} gutter={30}>
        <Col xs={12} sm={12}>
          <ImageGallery items={images} showBullets={false} showPlayButton={false} showFullscreenButton={false} />
        </Col>
        <Col xs={12} sm={12}>
          <Row gutter={30}>
            <Col xs={24} sm={24}>
              <span style={{ fontSize: '20px', color: '#191c1f', fontWeight: 'bold' }}>{data && data.postName}</span>
            </Col>
          </Row>
          <Row gutter={30} style={{ marginBottom: 5, marginTop: 10 }}>
            <Col xs={12} sm={12}>
              <span style={{ fontSize: '14px', color: '#5f6c72', fontWeight: 'bold' }}>預估售價:</span>
              <span style={{ fontSize: '14px', color: '#191c1f ', fontWeight: 'bold', marginLeft: 5 }}>TW${price}</span>
            </Col>
            <Col xs={12} sm={12}>
              <span style={{ fontSize: '14px', color: '#5f6c72', fontWeight: 'bold' }}>出貨時間:</span>
              <span style={{ fontSize: '14px', color: '#191c1f ', fontWeight: 'bold', marginLeft: 5 }}>{data && data.deliverDays}(工作)天</span>

            </Col>
          </Row>

          <Row gutter={30} style={{ marginBottom: 5, marginTop: 10 }}>
            <Col xs={12} sm={12}>
              <span style={{ fontSize: '14px', color: '#5f6c72', fontWeight: 'bold' }}>商品型號:</span>
              <span style={{ fontSize: '14px', color: '#191c1f ', fontWeight: 'bold', marginLeft: 5 }}>{data && data.prodModel}</span>
            </Col>
            <Col xs={12} sm={12}>
              <span style={{ fontSize: '14px', color: '#5f6c72', fontWeight: 'bold' }}>盒/單狀況:</span>
              <span style={{ fontSize: '14px', color: '#191c1f ', fontWeight: 'bold', marginLeft: 5 }}> {data && data.prodBox}</span>
            </Col>
          </Row>

          <Row gutter={30} style={{ marginBottom: 5, marginTop: 10 }}>
            <Col xs={12} sm={12}>
              <span style={{ fontSize: '14px', color: '#5f6c72', fontWeight: 'bold' }}>商品產地:</span>
              <span style={{ fontSize: '14px', color: '#191c1f ', fontWeight: 'bold', marginLeft: 5 }}> {data && data.mfgLocation}</span>

            </Col>
            <Col xs={12} sm={12}>
            </Col>
          </Row>
          <Row gutter={30} style={{ marginBottom: 5, marginTop: 10 }}>
            <Col xs={12} sm={12}>
              <span style={{ fontSize: '14px', color: '#5f6c72', fontWeight: 'bold' }}>商品重量:</span>
              <span style={{ fontSize: '14px', color: '#191c1f ', fontWeight: 'bold', marginLeft: 5 }}> {data && data.pkgWeight} {data && data.pkgWeightUnit}</span>

            </Col>
            <Col xs={12} sm={12}>
            </Col>
          </Row>
          <Row gutter={30} style={{ marginBottom: 5, marginTop: 10 }}>
            <Col xs={12} sm={12}>
              <span style={{ fontSize: '14px', color: '#5f6c72', fontWeight: 'bold' }}>目前競標賣家:</span>
              <span style={{ fontSize: '14px', color: '#191c1f ', fontWeight: 'bold', marginLeft: 5 }}>{people}人</span>

            </Col>
            <Col xs={12} sm={12}>

            </Col>
          </Row>
          <Row gutter={30} style={{ marginBottom: 5, marginTop: 10 }}>
            <Col xs={12} sm={12}>
              <span style={{ color: '#ee5858 ', fontSize: 14, textDecoration: 'underline', fontWeight: 'bold' }}>目前競標狀態</span>
            </Col>
            <Col xs={12} sm={12}>

            </Col>
          </Row>
          <Row gutter={30} style={{ marginBottom: 5, marginTop: 10 }}>
            <Col xs={12} sm={12}>
              <Button
                icon={<FaRegHeart />}
                type="text"
                danger={data && data.isActiveProdLike === 'Y' ? true : false}
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  marginBottom: 10,
                }}
                onClick={() => handleMouseEnter(data && data.postId)}
              >
                收藏({data && data.prodLike})
              </Button>
              <Button icon={<FaRegThumbsUp />}
                type="text"
                danger={thumbsUp ? true : false}
                style={{
                  marginTop: 10,
                  marginBottom: 10,

                }}
                onClick={() => handleThumbsUpEnter(data && data.postId)}

              >讚({thumbsUpNumber})

              </Button>
            </Col>
            <Col xs={12} sm={12}>

            </Col>
          </Row>

          <Row gutter={30}>
            <Col xs={6} sm={6} >
              <Button
                icon={<FaCartShopping />}
                size='large'
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  width: '100%',
                  borderColor: bided ? 'red' : '#fa8232 ',
                  backgroundColor: bided ? 'red' : '#fa8232',
                  color: 'white', // Optional: change text color when hovered
                }}
                onClick={() => handleBidEnter()}
              >
                BID{bilStatus}
              </Button>
            </Col>

          </Row>
        </Col>

      </Row >

      <Row justify="center" gutter={24}>
        <Col xs={22} sm={22}>
          商品說明
          <Divider />
          {data && (<div>
            {data.prodDesc}
          </div>
          )}
        </Col>
      </Row >
      <br />
      <br />
      <br />
      <Row justify="center" gutter={24}>
        <Col xs={10} sm={10}>
          商品留言
          <Divider />
          {data && (<div>
            <Table

              showHeader={false}
              columns={columns}
              dataSource={qData}
              pagination={false}
            />
          </div>
          )}
        </Col>
        <Col xs={12} sm={12}></Col>
      </Row >
      <Modal
        title="選擇商品小分類"
        open={modalpVisible}
        onOk={handlepModalOk}
        onCancel={handlepModalCancel}
        width={'60%'}
      >
        <Row gutter={5}>
          <Col sm={3}>
            分類選擇:
          </Col>
          <Col sm={6}>
            <Input placeholder='快選輸入' ></Input>
          </Col>
        </Row>
        <Row gutter={5}>
          <Col sm={3}>
            已選商品:
          </Col>
          <Col sm={4}>
            <Input value={selectedItems.length} ></Input>
          </Col>
          <Col sm={2}>
            件
          </Col>
          <Col sm={2}>
            預估總額$:
          </Col>
          <Col sm={4}>
            <Input value={totalPrice.toFixed(0)}  ></Input>
          </Col>
          <Col sm={2}>
            元
          </Col>
        </Row>
        <Row gutter={5}>
          <Col sm={3}>
            目前商品共計:
          </Col>
          <Col sm={4}>
            <Input value={bdata.length} ></Input>
          </Col>
          <Col sm={2}>
            件
          </Col>

        </Row>
        <Divider />
        <Row gutter={16}>
          {bdata.map(item =>
          (< Col span={6}>
            <Card >
              <img src={`/images/inventory/${item.photo}`} style={{ height: '80px', height: '80px', }} ></img>
              {item.name}
              <p >{item.price}
                <Checkbox
                  checked={item.check}
                  onChange={() => handleCheckboxChange(item.key)}
                  style={{ marginLeft: 10 }}
                >

                </Checkbox>
              </p>
            </Card>
          </Col>
          )
          )
          }


        </Row>
      </Modal>

    </div >)





}
export default ProductDescribe
