
import { Breadcrumb, Select, Layout, Card, Menu, theme, Button, Tooltip, Typography, Checkbox, Form, Input, Row, Col, Space, Steps, DatePicker, Carousel } from 'antd';
import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const StartPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/bnbarter/main')
  }, []);
  return (
    <div >

    </div>)





}
export default StartPage
