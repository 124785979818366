
import { Breadcrumb, Layout, Menu, theme, Button, Checkbox, Form, Input, Row, Col, Space } from 'antd';
import Icon, { FacebookFilled, InstagramFilled, LineHeightOutlined, LinkedinFilled, createFromIconfontCN } from '@ant-design/icons';
import { FaLine, FaFacebook, FaLinkedin, FaInstagram, FaGoogle } from "react-icons/fa6";
import { Link, Outlet } from 'react-router-dom';
const { Header, Content, Footer } = Layout;
const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js',
    // icon-javascript, icon-java, icon-shoppingcart (overridden)
    '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
  ],
});
const BasicSign = () => {
  return (
    <Layout className="layout-background">
      <Header className={`content-layout header-spacing`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/">
            <img
              src="/images/logobnbarter.png"
              alt="Logo"
              style={{
                height: '130px',
                float: 'left', // 圖片浮動在左側
              }}
            />
          </Link>
          競物王
          <span style={{ paddingLeft: 40, color: 'red', fontSize: 14, textDecoration: 'underline', }}>網站小幫手 </span>
        </div>
      </Header>
      <Content className="content-layout"
        style={{
          backgroundColor: '#FFF', minHeight: '80vh'
        }}
      >
        <div style={{ backgroundColor: '#fff', margin: 20 }} >
          <Outlet />

        </div>
      </Content>
      <Footer className={`content-layout footer-spacing`}
        style={{
          background: '#fff'
        }}
      >
        <Row style={{ display: 'flex', alignItems: 'baseline', marginTop: '100px' }}>
          <Col xs={22} sm={5} span={2}>

          </Col>
          <Col xs={22} sm={5} span={2}>
            <span className='footer-title'>bnbarter</span>
            <br />
            <br />
            <span className='footer-message'>All you can bid.</span>
          </Col>
          <Col xs={22} sm={4} span={2}>
            <span className='footer-second-title'>關於 bnbarter</span>
            <br />
            <br />
            <p className='footer-item'>bnbarter是什麼?</p>
            <p className='footer-item'>bnbarter政策條款</p>
            <p className='footer-item'>隱私權政策</p>
            <p className='footer-item'>會員小庫房</p>
            <p className='footer-item'>條惠特急標</p>
          </Col>
          <Col xs={22} sm={2} span={2}>
            <span className='footer-second-title'>服務中心</span>
            <br />
            <br />
            <p className='footer-item'>網站小幫手?</p>
            <p className='footer-item'>交易競標方式</p>
            <p className='footer-item'>b幣錢包</p>
            <p className='footer-item'>運費補助</p>
            <p className='footer-item'>退貨退款</p>
            <p className='footer-item'>聯絡客服</p>

          </Col>
          <Col xs={22} sm={4} span={2}>
            <span className='footer-second-title'></span>
            <br />
            <br />

            <p className='footer-item'>改善建議</p>
            <p className='footer-item'>防詐騙說明</p>
          </Col>
          <Col xs={22} sm={3} span={2}>
            <span className='footer-second-title'>關注我們</span>
            <br />
            <br />
            <div style={{ display: 'inline-block' }}>
              <FaFacebook style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
              <FaInstagram style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
              <FaLine style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
              <FaLinkedin style={{ fontSize: '30px', margin: 5, display: 'inline-block' }} />
            </div>
          </Col>


        </Row>
        <Row justify="center">
          <Col xs={24} sm={24} span={24} style={{ textAlign: 'center', color: '#5e6282', margin: 20, fontFamily: 'Poppins' }}>
            版權 2023-2024 Rocker Technology Ltd. 本公司保留所有權利。會員合約、私隱權通知、支付使用款、及 Cookie.
          </Col>
        </Row>

      </Footer>
    </Layout >

  )
}
export default BasicSign
