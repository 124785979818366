// ImageColumn.js
import React, { useState, useEffect } from 'react';
import request from '../../requests/api'
const ImageFile = ({ imgId, imageWidth }) => {
    const [imgPath, setImagePath] = useState('');
    const [imgData, setImgData] = useState(null);


    useEffect(() => {

        fetchImageData(imgId, setImgData);

    }, [imgId]);

    return imgData ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
                src={imgData}
                alt="Inventory"
                style={{ objectFit: 'contain', marginTop: 1, maxWidth: '99.5%' }}
            />
        </div>
    ) : null;
};

const fetchImage = async (skuId, setImagePath) => {
    try {
        const res = await request.get(`/bnbarter/mem-inventory-detail/${skuId}`);
        if (res.data.datas && res.data.datas[0]) {
            setImagePath(res.data.datas[0].imgId);
        }
    } catch (err) {
        console.error(err);
    }
};

const fetchImageData = async (imgPath, setImgData) => {
    try {
        const res = await request.get(`/bnbarter/mem-inventory-image/${imgPath}`, {
            responseType: 'arraybuffer',
        });
        const base64String = arrayBufferToBase64(res.data);
        setImgData(`data:image/jpeg;base64,${base64String}`);
    } catch (err) {
        console.error(err);
    }
};

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export default ImageFile;
